import { QueryFunction } from 'app2/views/shared'

export interface OptionDataItem {
  id:string;
  name?:string;
}

export async function findQueryOption<V, D, T extends OptionDataItem, R>(query:QueryFunction<V, D>, variables:V, nameOrObArray:number | string | T | string[] | T[], nameField?:string):Promise<R> {
  if (nameOrObArray == null || nameOrObArray == undefined) {
    return null;
  }

  const [success, result] = await query({variables, context:{requestPolicy: 'cache-first'}});

  let data = result.data;
  let item:T;

  if (data) {
    while (data && !Array.isArray(data)) {
      data = Object.values(data)[0];
    }

    const nameOrObj:number | string | T = Array.isArray(nameOrObArray) ? nameOrObArray[0] : nameOrObArray;
    item = (data as unknown as T[])?.find(t => compareOptionDataItems(t, nameOrObj, nameField));
  }

  return item as unknown as R;
}

function compareOptionDataItems<T  extends OptionDataItem>(a:T, b:number | string | T | string, nameField?:string) {
  nameField ||= 'name';

  if (!(a ?? false) || !(b ?? false)) {
    return false
  }
  
  if (typeof b == 'string') {
    return (a as any)[nameField] == b;
  }
  else
  if (typeof b == 'number') {
    return (a as any).id == b;
  }
  else
  if (typeof b == 'object') {
    return a.id == b.id && getTypename(a) == getTypename(b);
  }
}

export function getTypename(a:any) {
  if (!a) {
    return '';
  }

  return a.typename || a._typename || a.__typename;
}

