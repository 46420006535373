import * as React from 'react'
import * as ReactIs from 'react-is'
import shallowEqual from 'shallowequal';

import { MultiContext } from '../utils';
import { callbackResultShield, ShieldContext } from '../shield';

import { FormContext } from './Form'
import { FormModel, FieldInfo, UpdateType } from './FormModel';
import { FieldModel } from './FieldModel';
import { FieldComponentProps, FieldPlaceholder, FieldPlaceholderType, FCT } from './FieldComponentProps';
import { FieldParent } from './FieldParent';
import { FieldValue } from './FieldValue';

// use this as a component to indicate you just want to take
// the results of the formatter
export const FormatOnly = {};

export type FieldRendererProps<T = any, P extends keyof T = any> = {
  componentProps: any;
  componentHasLabel:boolean;
  field:FieldModel<T, P>;
  info:FieldInfo<T, P>;
  editing:boolean;
  disallowNone:boolean;
  children:React.ReactNode;
};

type CoreFieldProps<T, P extends keyof T = any>  = Partial<FieldModel<T, P>> & {
  label?:React.ReactNode;//this will get ignored unless used in RepeatingSection
  parents?:(string | number)[];
  render?:(props:FieldRendererProps<T, P>) => React.ReactNode;
  editing?:boolean | ((info:FieldInfo<T, P>) => boolean);
  autoLoader?:boolean;
  componentRef?: React.MutableRefObject<any>;
  children?:React.ReactNode;
}

export type FieldMode = 'both' | 'display' | 'edit' | 'none';

export type FieldProps<T, P extends keyof T, C1 extends FCT, C2 extends FCT = any> = 
    CoreFieldProps<T, P> & Omit<FieldComponentProps<C1>, 'onChange'> & {
  edit?: Omit<FieldComponentProps<C1>, 'onChange'>;
  display?: Omit<FieldComponentProps<C2>, 'onChange'>;
  // dictates which mode to render in.  this does not dictate which mode the field is in.
  // that is inherited from the form or by th editing flag.
  mode?:FieldMode;
}

// Generic parameters, much can be inferred and don't need to be specified
// T = the main form object type
// P = this fields property, should be a string that is the name of a property in T
// C1 = the edit components type
// C2 = the display components type
export class Field<T = any, P extends keyof T = any, C1 extends FCT = any, C2 extends FCT = any> extends React.Component<FieldProps<T, P, C1, C2>> {
  static contextType = MultiContext;

  context:FormContext<T> & ShieldContext;
  parents:(string | number)[];
  path:string;
  // this is the name passed into via props, which is not necessarily the same as the 
  // field name as props.name can have partial paths, but field.name can not.
  name:P;
  field:FieldModel<T, P>;
  fieldComponent:FieldComponentProps<any>;
  prevProps:FieldProps<T, P, C1, C2>;
  form:FormModel<T>;
  _editing:boolean;

  get formContext() {
    return this.context?.formInfo;
  }

  get shield() {
    return this.context?.shield;
  }

  get editing():boolean {
    if (this._editing !== undefined) {
      return this._editing;
    }

    const editing = (this.props.editing !== undefined ? Boolean(this.props.editing) : this.formContext?.editing);
    return editing === undefined ? true : editing;
  }

  componentWillUnmount() {
    this.removeField();
  }

  onUpdate() {
    const form = this.formContext?.form;
    const formChanged = this.form != form;
    const fieldChanged = !this.prevProps || !shallowEqual(this.getFieldFromProps(this.props as FieldProps<T, P, C1, C2>), this.getFieldFromProps(this.prevProps)) || !shallowEqual(this.parents, this.getParents());

    if (formChanged || fieldChanged) {
      this.updateField(formChanged);
    }

    this._editing = undefined;
    
    if (typeof this.props.editing == 'function') {
      const info = this.form?.getInfo?.(this.parents, this.name) || {} as FieldInfo<T>;
      this._editing = Boolean(this.props.editing(info));
    }

    // we should be checking if required is a function and passing in a field info it is
    // but this is good enough for now for computing a placeholder
    const required = Boolean(this.field.required)
    this.fieldComponent = mergeFieldComponentProps(this.props as FieldProps<T, P, C1, C2>, this.editing, required);

    this.prevProps = this.props as FieldProps<T, P, C1, C2>;
  }

  updateField(formChanged:boolean) {
    // removing the field will cause the form to revalidate
    // which isn't desireable (because it can clear server errors)
    // when the only reason we are updating is because the user
    // of fields is using a locally declared validator or change,
    // so the instance keeps changing...in that case replace the field
    if (!formChanged && this.field && this.name && this.name == this.props.name && shallowEqual(this.parents, this.getParents())) {
      this.field = this.getFieldFromProps(this.props);
      this.name = this.props.name;
      //save the returned field so we have the most up to date field
      this.field = this.form.updateField?.(this.parents, this.name, this.field) || this.field;
    }
    else {
      this.removeField();
      this.addField();
    }
  }

  removeField() {
    if (this.field && this.name) {
      this.form?.unsubscribe?.(this.onFormUpdate);
      this.form.removeField?.(this.parents, this.name);
      this.form = null;
      this.field = null;
      this.name = null;
      this.path = null;
    }
  }

  addField() {
    this.form = this.formContext?.form;

    const parents = this.getParents();
    const existing = this.props.name ? this.form?.getField?.(parents, this.props.name) : undefined;

    this.field = this.getFieldFromProps(this.props, existing);
    this.name = this.props.name;
    this.parents = parents;

    if (this.name) {
      this.path = parents.concat(this.name as string).join('.');
      this.form?.subscribe?.(this.onFormUpdate);
      //save the returned field so we have the most up to date field
      this.field = this.form.addField?.(this.parents, this.field) || this.field;
    }
  }
  
  getFieldFromProps(props:Readonly<FieldProps<T, P, C1, C2>>, existing?:FieldModel<T, P>) {
    return {
      name: props.name,
      validators: props.validators || props.component?.validators || props.edit?.validators || props.display?.validators,
      required: props.required || existing?.required,
      disabled: props.disabled !== undefined ? props.disabled : existing?.disabled,
      boolean: props.boolean !== undefined ? props.boolean : existing?.boolean,
      readOnly: props.readOnly !== undefined ? props.readOnly : existing?.readOnly,
      assignIds: props.assignIds !== undefined ? props.assignIds : existing?.assignIds,
      onChange: props.onChange || existing?.onChange
    };
  }

  getParents() {
    return this.props.parents?.slice?.() || this.formContext?.parents?.slice?.();
  }

  render() {
    this.onUpdate();

    const formMode = this.editing ? 'edit' : 'display';
    const mode = this.props.mode;

    if (mode === 'none' || (mode !== undefined && mode != 'both' && mode != formMode)) {
      return <></>;
    }
  
    const rendererProps = this.createRenderProps()

    return this.props.render
      ? this.props.render(rendererProps) 
      : rendererProps.children;
  }

  createRenderProps() {
    let {
      // CoreFieldProps
      form, label, parents, render, editing, autoLoader, componentRef, children, 
      // FieldModel
      name, validators, required, boolean, onChange,
      // FiedComponentProps
      readOnly, assignIds, none, format, parse, copy, paste, stretch, displayRequired, valueProperty, getValueProperty, onChangeProperty, onBlurProperty, errorProperty, errorsProperty, fieldProperty, disabledProperty, readOnlyProperty, disallowNone, labelProperty, formModeProperty, changeHandler, blurHandler, component, onClick, edit, display, mode,
      ...remaining} = this.fieldComponent;

    const props:any = {ref:componentRef, children, ...remaining};
    const ret = {field: this.field, info: undefined, editing: this.editing, children: undefined, componentProps:props, disallowNone: disallowNone || none === false} as FieldRendererProps<T, P>;
    let hasErrors = false;
    let showNone = none !== false && !this.editing && !props.children && !disallowNone && (this.name || none !== undefined);

    if (props.ref === undefined) {
      delete props.ref;
    }

    if (props.children === undefined) {
      delete props.children;
    }

    if (this.name || this.parents?.length > 1) {
      const isElement = this.isElement;

      ret.info = this.form?.getInfo?.(this.parents, this.name) || {} as FieldInfo<T>;

      // attempt to ignore errors on fields that are used to 
      // just render other fields which will contain the errors themselves
      if (this.props.name === undefined && this.props.render) {
        ret.info.errors = [];
      }

      hasErrors = ret.info.errors?.length > 0;

      const isReadonly = this.concatProps(isElement, readOnlyProperty, props, ret.info?.readOnly) || readOnly;
      showNone = none !== false && (!this.editing || isReadonly) && isNone(ret.info.value) && !disallowNone;

      if (!showNone && valueProperty) {
        const formattedValue = this.fieldComponent.format ? this.fieldComponent.format(ret.info.value, this.props, ret.info) : ret.info.value;
        props[valueProperty] = props[valueProperty] || formattedValue;
      }

      if (onChangeProperty && this.name) {
        props[onChangeProperty] = this.onChange;
      }

      if (onBlurProperty && this.name) {
        props[onBlurProperty] = this.onBlur;
      }

      if (hasErrors) {
        props['className'] = (props['className'] || '') + ' hr_error_locator';
      }

      if (errorProperty) {
        props[errorProperty] = hasErrors;
      }

      if (errorsProperty) {
        props[errorsProperty] = ret.info.errors;
      }

      if (fieldProperty) {
        props[fieldProperty] = ret.info;
      }

      if (disabledProperty) {
        props[disabledProperty] = this.concatProps(isElement, disabledProperty, props, ret.info.disabled);
      }

      if (readOnlyProperty) {
        props[readOnlyProperty] = isReadonly;
      }

      if (labelProperty) {
        props[labelProperty] = label;
        ret.componentHasLabel = true;
      }

      if (formModeProperty) {
        props[formModeProperty] = this.editing ? 'edit' : 'display';
      }

      if (onClick) {
        props['onClick'] = this.onClick;
      }

      props['data-field'] = remaining['data-field'] || label || ret.info.name;

      if (typeof props['placeholder'] == 'function') {
        props['placeholder'] = props['placeholder'](ret.info.value, this.props, ret.info);
      }
    }

    if (showNone) {
      ret.children = getNone(none, hasErrors, ret.info?.disabled)
    }
    else
    if (component) {
      ret.children = this.createElement(props);
    }
    else 
    if (this.props.children) {
      ret.children = <FieldParent name={this.props.name}>{this.props.children}</FieldParent>;
    }

    return ret;
  }

  createElement(props:any) {    
    if (this.fieldComponent.component == FormatOnly) {
      return props.children || props[this.fieldComponent.getValueProperty || this.fieldComponent.valueProperty];
    }

    return this.isElement
      ? React.cloneElement(this.element, props)
      : <this.Component {...props} />
  }

  get isElement(): boolean {
    return ReactIs.isElement(this.fieldComponent.component);
  }

  get element(): React.ReactElement {
    return this.fieldComponent.component as React.ReactElement;
  }

  get Component(): React.ComponentType {
    return this.fieldComponent.component as React.ComponentType;
  }

  // for boolean properties, we look at the form model info, the passed in props,
  // and the element props (if we received an element), any true value trumps any falses
  // so for example if one of the three says disabled, then the component is disabled
  concatProps(isElement:boolean, name:string, props:any, infoValue:boolean) {
    const elemProp = isElement && name in this.element.props
      ? this.element.props[name]
      : undefined;
    
    return Boolean(elemProp) || Boolean(props[name]) || infoValue;
  }

  onFormUpdate = (form:FormModel, type:UpdateType) => {
    if (type.reset || type.fields[this.path]) {
      this.forceUpdate();
    }
  }

  onChange = (...args:any[]) => {
    let result = [];

    if (this.form) {
      const eventOrValue: React.SyntheticEvent<HTMLInputElement> = args[0];

      // ignore events coming from nested controls (such as an input inside a radio)
      // this means components must dispatch their change event directly from their
      // root dom node (else the change event will be considered a nested control and ignored)
      if (eventOrValue?.target && eventOrValue?.target != eventOrValue?.currentTarget) {
        return;
      }

      const possibleSemanticUiValue = args[1];
      const valuePropName = this.fieldComponent.getValueProperty || this.fieldComponent.valueProperty;
      const target = eventOrValue?.currentTarget as any;
      let value:any = target?.[valuePropName];

      if (!target || !(valuePropName in target)) {
        if (possibleSemanticUiValue && possibleSemanticUiValue.value) {
          value = possibleSemanticUiValue.value;
        }
        else {
          value = eventOrValue;
        }
      }

      if (this.fieldComponent.parse) {
        const info = this.form?.getInfo?.(this.parents, this.name);
        value = this.fieldComponent.parse(value, this.props, info);
      }

      result.push(this.form.setValue(this.parents, this.name, value));

      // due to react needing a synchronious update to avoid the selection jumping
      this.forceUpdate();
    }

    const existingOnChage =
      (this.fieldComponent as any)[this.fieldComponent.onChangeProperty] ||
      (this.isElement
        ? this.element.props[this.fieldComponent.onChangeProperty]
        : undefined);

    if (existingOnChage) {
      args.push(this.form?.getInfo?.(this.parents, this.name));
      result.push(existingOnChage(...args));
    }

    if (this.props.autoLoader) {
      callbackResultShield(this.context.shield, result);
    }

    return result;
  }

  onBlur = (...args:any[]) => {
    if (this.form) {
      this.form.touch(this.parents, this.name);
    }

    const existingOnBlur = (this.fieldComponent as any)[this.fieldComponent.onBlurProperty] ||
      (this.isElement
        ? this.element.props[this.fieldComponent.onBlurProperty]
        : undefined);

    if (existingOnBlur) {
      existingOnBlur(...args);
    }
  }

  onClick = (event:React.MouseEvent) => {
    const info = this.form?.getInfo?.(this.parents, this.name);
    return this.fieldComponent.onClick(event, info);
  }
}

export type SeparatedFieldProps = {
  model:FieldModel<any, any>;
  component: FieldComponentProps<any>;
  hasModel:boolean;
  hasComponent:boolean;
  hasField:boolean;
}

export function separateFieldProps(props:any):SeparatedFieldProps {
  const {name, validators, required, boolean, onChange, readOnly, assignIds, none, format, parse, copy, paste, stretch, displayRequired, getValueProperty, valueProperty, onChangeProperty, onBlurProperty, errorProperty, errorsProperty, fieldProperty, disabledProperty, readOnlyProperty, disallowNone, labelProperty, formModeProperty, changeHandler, blurHandler, 
    component, onClick, edit, display, mode} = props;

  const separatedProps = {
    model:{name, validators, required, boolean, onChange, assignIds},
    component:{readOnly, none, format, parse, copy, paste, stretch, displayRequired, getValueProperty, valueProperty, onChangeProperty, onBlurProperty, errorProperty, errorsProperty, fieldProperty, disabledProperty, readOnlyProperty,disallowNone, labelProperty, formModeProperty, changeHandler, blurHandler, component, onClick, edit, display, mode},
    hasModel: false,
    hasComponent: false,
    hasField: false,
  }

  separatedProps.hasModel = Object.values(separatedProps.model).filter(val => val !== undefined).length != 0;
  separatedProps.hasComponent = Object.values(separatedProps.component).filter(val => val !== undefined).length != 0;
  separatedProps.hasField = separatedProps.hasModel || separatedProps.hasComponent;

  return separatedProps;
}

// this function takes the component, edit and display properties
// that allow for nesting (such as component={{component: Radio, valueField: 'checked'}}
// and a) chooses the right component depending on the form mode and
// b) collapses the nesting so its a flat component definition.
export function mergeFieldComponentProps<T, P extends keyof T, C1 extends FCT, C2 extends FCT = any>(props:FieldProps<T, P, C1, C2>, editing:boolean, required?:boolean) {
    const componentPropName = editing && !props.readOnly ? 'edit' : 'display';

    let fieldComponent:FieldComponentProps<any> = Object.assign({}, props);

    // This fixes the case where a label property was specified as undefined because
    // a component was passing along the property but didn't explicitly mean it to
    // be empty.  if this isn't removed it prevents the label property being merged 
    // if its defined on edit, display or component.
    if ("label" in fieldComponent && fieldComponent.label === undefined) {
      delete fieldComponent.label;
    }

    // field components can have an on change specified in component, edit or display, 
    // but the core onChange property is passed to the field definition
    delete fieldComponent.onChange;

    fieldComponent.component = fieldComponent[componentPropName] || fieldComponent.component;

    // hack to detect something like col: {display: {format: () => something}}, where there's no component specified on the last layer
    if (fieldComponent.component && !fieldComponent.component[componentPropName] && !fieldComponent.component.component && fieldComponent.component.constructor == Object && !fieldComponent.component.$$typeof && fieldComponent.component !== FormatOnly) {
      fieldComponent = Object.assign({}, fieldComponent, fieldComponent.component);
      fieldComponent.component = fieldComponent[componentPropName] = undefined;
    }
  
    if (!fieldComponent.component && !props.children) {
      fieldComponent.component = FieldValue;
    }

    function getNext(c:any) {
      return c ? c[componentPropName] || c.component : undefined;
    }

    let cur = fieldComponent;
    
    while (getNext(getNext(cur))) {
      const next = getNext(cur);
      const component = getNext(next);
      fieldComponent = Object.assign({}, next, fieldComponent);
      fieldComponent.component = fieldComponent[componentPropName] = component;
      cur = fieldComponent;
    }

    const componentType = ReactIs.isElement(fieldComponent.component) ? fieldComponent.component.type : fieldComponent.component;

    fieldComponent = Object.assign({}, componentType?.fieldProps || defaultFieldProps, fieldComponent);

    const label = props.label || fieldComponent.label;

    fieldComponent.placeholder = computePlaceholder(label, fieldComponent.placeholder, props.displayRequired || props.required || fieldComponent.required || required);

    if (!fieldComponent.placeholder) {
      delete fieldComponent.placeholder;
    }

    return fieldComponent;
}

export const defaultFieldProps = {
  valueProperty: 'value',
  errorProperty: 'error',
  onChangeProperty: 'onChange',
  onBlurProperty: 'onBlur',
  disabledProperty: 'disabled'
}

export function computePlaceholder(label:string | React.ReactNode, placeholder:FieldPlaceholderType, required:boolean = false) {
  let computed:FieldPlaceholderType;

  if (typeof placeholder == 'function') {
    computed = placeholder;
  }
  else
  if (typeof placeholder == 'string') {
    computed = placeholder;
  }
  else
  if (!label || typeof label != 'string') {
    computed = undefined;
  }
  else
  if (placeholder === true || placeholder === FieldPlaceholder.enter) {
    computed = 'Enter ' + label.toLowerCase();
  }
  else
  if (placeholder === FieldPlaceholder.select) {
    computed = 'Select ' + label.toLowerCase();
  }

  if (computed && required) {
    computed = computed + ' *';
  }

  return computed;
}

export function isNone(value:any) {
  return value === undefined || value === null || value === '' || (Array.isArray(value) && value.length == 0);
}

export function useDefaultNoneMsg(noneProp:any) {
  return noneProp === undefined || noneProp === null || noneProp === true || noneProp === '';
}

const defaultNoneMsg = 'None';

export function getNone(noneProp:any, error:boolean, disabled:boolean, readOnly:boolean = true):React.ReactNode {
  const msg = useDefaultNoneMsg(noneProp) ? defaultNoneMsg : noneProp;

  return typeof msg == 'string'
    ? <FieldValue error={error} disabled={disabled} readOnly={readOnly}>{msg}</FieldValue>
    : <>{msg}</>;
}
