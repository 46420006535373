import * as React from 'react';

import { DiscountDefinitionFormat } from 'app2/api';
import { Dropdown, VBox, BoxProps, Combobox, useFormInfo, Input, CurrencyInput, PercentInput, Field, FieldInfo, formatCurrency, formatPercent, Option, useFormSubscription } from 'app2/components';

type Form<RateType extends PropertyKey, RateValue extends PropertyKey, RateTypeEnum> = {
  [K in RateType]: RateTypeEnum } & {
  [K in RateValue]: number
};

export type RateComboOption = 'none' | 'fixed' | 'percentage' | (Option & {field?:JSX.Element});

interface Props2<RateType extends PropertyKey, RateValue extends PropertyKey, RateTypeEnum> extends BoxProps {
  typeFieldName:RateType;
  valueFieldName:RateValue;
  fixedValue:RateTypeEnum;
  percentageValue:RateTypeEnum;
  options?:RateComboOption[];
  swap?:boolean;
  value?:Form<RateType, RateValue, RateTypeEnum>;
}

export function RateCombo<RateType extends PropertyKey, RateValue extends PropertyKey, RateTypeEnum>(props:Props2<RateType, RateValue, RateTypeEnum>) {
  const {typeFieldName, valueFieldName, fixedValue, percentageValue, options:propsOptions, swap, ...remaining} = props;

  const options = getOptions();
  const formInfo = useFormInfo<Form<RateType, RateValue, RateTypeEnum>>();
  const form = formInfo.form;

  useFormSubscription({ field: 'format' });

  const type = formInfo.form.getValue(formInfo.parents, typeFieldName);
  const selected = options.find(o => o.value == type)

  function render() {
    return <VBox width="100%" {...remaining}>
      {formInfo.editing ? renderEdit() : renderDisplay()}
    </VBox>
  }

  function renderDisplay() {
    return selected.field;
  }

  function renderEdit() {
    const rateTypeField = {
      name: typeFieldName,
      edit: { component: Dropdown, options }
    }
      return <Combobox mb="$4" flex={1} swap={swap} dropdown={<Field {...{...rateTypeField, options }} onChange={handleFormatChange} />} input={selected.field} />
  }

  function handleFormatChange(value:RateTypeEnum, info:FieldInfo<Form<RateType, RateValue, RateTypeEnum>>) {
    // clears the previous selected format type's value
    if (selected && selected.field && selected.field.props.name) {
      form.setValue(info.parents, selected.field.props.name, undefined);
    }
  }

  function getOptions():(Option & {field?:React.ReactElement})[] {
    return React.useMemo(() => propsOptions.map(createOption), [typeFieldName, valueFieldName, fixedValue, percentageValue, propsOptions]);
  }

  function createOption(o:RateComboOption) {
    if (o == 'none') {
      return noneOption(valueFieldName);
    }
    else
    if (o == 'fixed') {
      return fixedOption(valueFieldName, fixedValue);
    }
    else
    if (o == 'percentage') {
      return percentageOption(valueFieldName, percentageValue);
    }
    else {
      return o
    }
  }

  function noneOption<RateValue extends PropertyKey, RateTypeEnum>(valueFieldName:RateValue) {
    return {
      label: 'None',
      value: null as RateTypeEnum,
      field: <Field name={valueFieldName} edit={{component: <Input disabled />}} />
    }
  }
  
  function fixedOption<RateValue extends PropertyKey, RateTypeEnum>(valueFieldName:RateValue, fixedValue:RateTypeEnum) {
    return {
      label: 'Fixed amount',
      value: fixedValue,
      field: <Field name={valueFieldName} display={{format:formatCurrency}} edit={{component: <CurrencyInput min={0} max={9999} />}} />
    }
  }
  
  function percentageOption<RateValue extends PropertyKey, RateTypeEnum>(valueFieldName:RateValue, percentageValue:RateTypeEnum) {
    return {
      label: 'Percentage',
      value: percentageValue,
      field: <Field name={valueFieldName} display={{format:formatPercent}} edit={{component: <PercentInput />}} />
    }
  }  

  return render();
}

export function createRateCombo<RateType extends PropertyKey, RateValue extends PropertyKey, RateTypeEnum>(typeFieldName:RateType, valueFieldName:RateValue, fixedValue:RateTypeEnum, percentageValue:RateTypeEnum, options:RateComboOption[]) {
  return function (props:Omit<Props2<RateType, RateValue, RateTypeEnum>, 'typeFieldName' | 'valueFieldName' | 'fixedValue' | 'percentageValue'>) {
    return <RateCombo typeFieldName={typeFieldName} valueFieldName={valueFieldName} fixedValue={fixedValue} percentageValue={percentageValue} options={options} {...props} />
  }
}

export const DiscountCombo = createRateCombo<'format', 'amount', DiscountDefinitionFormat>('format', 'amount', DiscountDefinitionFormat.Fixed, DiscountDefinitionFormat.Percentage, ['fixed', 'percentage']);
