import * as React from 'react';
import { isEmpty } from 'lodash-es';

import { Course, CourseStatus } from 'app2/api';
import { Dropdown, DropdownProps, HBox, Option, OptionValue, Tag, useFormInfo } from 'app2/components';
import { CourseLink } from 'app2/views/shared-public';

import { CoursesQueryVars } from './CoursesQueryVars';
import { CoursePickerSelections, useCoursePickerQuery } from './generated';

interface Props extends Pick<DropdownProps, 'multiple' | 'onChange' | 'value' | 'selectedStyle'> {
  queryVars:CoursesQueryVars;
  // currently filterings is server side, this will change when move to the new query api
  filter?:(course:CoursePickerSelections) => boolean;
  valueType?:'id' | 'object';
}

export function CoursePicker(props:Props) {
  const { queryVars, filter, multiple, onChange, value:propsValue, selectedStyle, valueType, ...remaining } = props;

  const form = useFormInfo();

  const emptyValue = isEmpty(propsValue);
  const displableValue = Array.isArray(propsValue) ? propsValue?.[0]?.name : propsValue?.name;
  const value = getValue();

  const courses = getOptions();

  function render() {
    return form.editing 
      ? <Dropdown options={courses} value={value} multiple={multiple} onChange={onChange} selectedStyle={selectedStyle} {...remaining} />
      : <HBox width='100%' gap='$4' vAlign="center" display='inline-flex' flexWrap='wrap'>
        {value.
          map((id:string) => courses.find((c:Option) => c.value == id)).
          filter((c:Option) => c).
          map((c:Option) => <Tag key={c.value}><CourseLink course={{id: c.value, name: c.label as string}} /></Tag>)}</HBox>
    }

  function getOptions() {
    const variables = queryVars && !queryVars.statuses ? {...queryVars, statuses: defaultStatuses} : queryVars;
    const [result] = useCoursePickerQuery({variables, pause: !queryVars || (!form.editing && emptyValue) || (!form.editing && displableValue)});

    const courses = React.useMemo(() => {
      return displableValue && !form.editing
        ? propsValue.map((v:any) => ({label: getObjectLabel(v), value: v.id}))
        : getOptionsFromQuery(result.data?.seasonCourses?.items)
    }, [result.data]);

    return courses;
  }

  function getOptionsFromQuery(items:CoursePickerSelections[]) {
    return items?.
      filter(c => filter ? filter(c) || isSelected(c.id) : true).
      map(c => ({label: getObjectLabel(c), value: getOptionValue(c)})) || []
  }

  function getObjectLabel(c:Partial<Pick<Course, 'name'| 'disambiguatedName'>>) {
    return c.disambiguatedName || c.name
  }

  function getOptionValue(course:CoursePickerSelections) {
    return valueType == 'object' ? course : course.id
  }

  function getValue():OptionValue[] {
    return displableValue ? (Array.isArray(propsValue) ? propsValue.map((v: any) => v.id) : propsValue.id) : propsValue;
  }

  function isSelected(id:string) {
    return value?.some(v => v == id);
  }

  return render();
}

CoursePicker.defaultValues = {
  valueType: 'id'
}

const defaultStatuses = [CourseStatus.Draft, CourseStatus.Request, CourseStatus.Upcoming, CourseStatus.Enrolling, CourseStatus.Active, CourseStatus.Completed];

