import * as React from 'react';
import { map, omit } from 'lodash-es';

import { DiscountDefinitionFormat, DiscountKind, DiscountDefinitionInput } from 'app2/api';
import { Field, FormModel, InputField, Panel, RepeatingSection, useForm, DropdownField, FieldRendererProps, Tag, palette } from 'app2/components';

import { DiscountForm } from './DiscountForm';
import { OrganizerDiscountsSelections, organizerUpsertDiscounts, useOrganizerDiscountsQuery } from './gql';

type Discounts = OrganizerDiscountsSelections[];

interface FormValues {
  discounts: Discounts;
}

interface Props {
  ownerId: string;
  ownerType: 'season' | 'site';
}

export function Discounts(props: Props) {
  const { ownerId, ownerType } = props;
  const [result, reeexecuteDiscountsQuery] = useOrganizerDiscountsQuery({ variables: { ownerId, ownerType }, context: React.useMemo(() => ({ additionalTypenames: ['OrganizerDiscountDefinition'] }), []) });
  const discounts = result.data?.discountDefinitions || [];
  const form = useForm<FormValues>({ discounts }, [discounts]);

  function render() {
    return (
      <Panel icon="Tag" title="Discounts" onOk={onOk} type="toggle" form={form}
        subtitle="Each enrollment can only be paired with one discount code. If more than one discount is applied, we will automatically choose the one that offers the greatest savings.">
        <RepeatingSection name="discounts" add="Add discount" onRemove="archive" numbered  defaultRecord={defaultRecord}
          fields={[
            <Field component={DiscountForm} ownerType={ownerType} ownerId={ownerId} />,
            'remove'
          ]}
        />
      </Panel>
    );
  }

  async function onOk(form: FormModel<FormValues>) {
    const discounts = extractInputs(form);
    const [success] = await organizerUpsertDiscounts({ variables: { ownerId, ownerType, discounts }, error: form });
    if (success) {
      reeexecuteDiscountsQuery();
    }
    return success;
  }

  function extractInputs(form: FormModel<FormValues>): DiscountDefinitionInput[] {
    return map(form.values.discounts, d => omit(d, ['usesCount']));
  }

  return render();
}

const defaultRecord = { code: '', active: true, kind: DiscountKind.Activity, format: DiscountDefinitionFormat.Percentage, amount: 0, usesCount: 0 };
