import * as React from 'react'

import { Tabs } from 'app2/views/shared-public';

import { GeneralInfo } from '../general-info';
import { PaymentTools } from '../payment-tools';
import { Policies } from '../policies';
import { Team } from '../team';

import { OrganizerPage, useCurrentSite } from '../shared'

export function Setup() {
  const { site } = useCurrentSite();

  return <OrganizerPage title='Setup'>
    <Tabs baseUrl={`/organizer/${site?.slug}/setup/:tab`} urlParameter='tab' tabs={[{
      label: 'General info',
      content: <GeneralInfo />,
      name: 'general',
    }, {
      label: 'Payment tools',
      content: <PaymentTools />,
      name: 'payment',
    }, {
      label: 'Policies',
      content: <Policies />,
      name: 'policies',
    }, {
      label: 'Team',
      content: <Team />,
      name: 'team',
    } ]} />
    
  </OrganizerPage>
}