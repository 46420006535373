export type DeepPartial<T> = {[P in keyof T]?: DeepPartial<T[P]>};

export * from './account';
export * from './company';
export * from './error';
export * from './events';
export * from './graphql';
export * from './organizer';
export * from './parent';
export * from './public';
export * from './shared';
export * from './shared-authorized';
export * from './teacher';
export * from './query';
export * from './urql';

// some utility types for identifying type mismatches from VSC

export type Diff<T, U> = T extends U ? never : T;

export type KeysDifference<T, U> = {
  [K in keyof T]: K extends keyof U ? Diff<T[K], U[K]> : K;
};

export type CheckType<T, U> = KeysDifference<T, U>;

// example usage
// type Result = CheckType<Parameters<typeof CourseUtils.getCourseAbilities>[0], CourseSelections>;
// then select Result and bring up the typescript type explorer.  anything marked "never" is a type match.  anything not marked never is a mismatch.

// creates a new type that makes the specified properties optional
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

// these are missing from the version of lodash-es we are using
export interface DebounceSettings {
	/**
	 * The number of milliseconds to delay.
	 * @default 0
	 */
	wait?: number;

	/**
	 * The maximum time `func` is allowed to be delayed before it's invoked
	 * @default null
	 */
	maxWait?: number | null;
}

export interface Cancelable {
  cancel(): void;
  flush(): void;
}
