import * as React from 'react';
import pluralize from 'pluralize';

import { RefundKind } from 'app2/api';
import { Box, Dropdown, VBox, BoxProps, Combobox, CurrencyInput, PercentInput, Field, FieldInfo, useFormSubscription, FormModel, NumberInput } from 'app2/components';
import { courseKindBehavior } from 'app2/views/shared-public';

import { CourseSelections } from '../../../generated';

import { RefundBreakdown, RefundBreakdownType } from '../../../../enrollment/RefundBreakdown';

interface FormWithRefund {
  kind: RefundKind;
  amount: number;
}

interface Props extends BoxProps {
  form: FormModel<any>;
  breakdown: RefundBreakdownType;
  perSessionEnabled: boolean;
  course: CourseSelections;
}

export function RefundCombo(props: Props) {
  const { breakdown, form, perSessionEnabled, ...remaining } = props;

  useFormSubscription({ field: 'kind' });

  function render() {
    return (
      <VBox width="100%" {...remaining}>
        {renderFields()}
      </VBox>
    );
  }

  function renderFields() {
    const refundProps = form?.values;
    const withdraw = refundProps?.kind == RefundKind.Withdraw;
    const disabled = !refundProps?.kind || refundProps?.kind == null || withdraw;
    const perSession = refundProps?.kind == RefundKind.PerSession;

    const input = withdraw ? <PercentInput disabled={disabled} /> : perSession ? <NumberInput disabled={disabled} min={1} max={20} /> : <CurrencyInput disabled={disabled} min={0} max={9999} />;

    return (
      <Box layout={['vbox', 'hbox']} width="100%" vAlign={[undefined, 'top']} hItemSpace={[undefined, '$4']}>
        <Combobox
          mb="$4"
          flex={1}
          dropdown={<Field {...{ name: 'kind', edit: { component: Dropdown, options: refundOptions() } }} onChange={handleKindChange} />}
          input={<Field {...{ name: 'amount' }} component={input} disabled={withdraw} />}
        />
        <RefundBreakdown {...breakdown} boxProps={{ width: '300px' }} />
      </Box>
    );
  }

  function handleKindChange(value: string, _info: FieldInfo<FormWithRefund>) {
    // TODO: Figure out why these fields don't change when the initial kind changes.
    form.setValue('amount', value === RefundKind.PerSession ? 1 : 0, {bypassDisabled: true});
  }


  function refundOptions() {
    const options = [
      {
        label: 'Full',
        value: RefundKind.Withdraw
      }
    ];

    if (props.course.vendor.company?.userCompanyRole) {
      options.push(
        {
          label: `Number of ${pluralize(courseKindBehavior[props.course.kind].terms.session)}`,
          value: RefundKind.PerSession
        },
        {
          label: 'Other amount',
          value: RefundKind.Custom
        }
      );

      if (!perSessionEnabled) {
        options.splice(1, 1);
      }
    }

    return options;
  }

  return render();
}
