import * as React from 'react'

import { CourseKind } from 'app2/api'
import { DropdownField, OptionPicker, OptionPickerProps } from 'app2/components';

interface CourseKindPickerProps extends OptionPickerProps {
  kinds?:CourseKind[]
}

export function CourseKindPicker(props:CourseKindPickerProps) {
  const {kinds, ...remaining} = props;

  const options = React.useMemo(() => optionsForKinds(kinds), [kinds]);

  return <OptionPicker options={options} {...remaining} />
}

function optionsForKinds(kinds:CourseKind[]) {
  return courseKindOptions.filter(o => !kinds || kinds.includes(o.value));
}

CourseKindPicker.fieldProps = OptionPicker.fieldProps;
CourseKindPicker.defaultProps = {
  type: 'button-strip'
}

const courseKindOptions = [{
  label: 'Enrichment',
  value: CourseKind.Enrichment,
}, {
  label: 'Extended care',
  value: CourseKind.ExtendedCare,
}, {
  label: 'Camp',
  value: CourseKind.Camp,
}, {
  label: 'Lessons',
  value: CourseKind.LessonSet,
}, {
  label: 'Time slot',
  value: CourseKind.TimeSlot,
}, {
  label: 'Team fee',
  value: CourseKind.AthleticReg,
}, {
  label: 'Individual fee',
  value: CourseKind.AthleticIndividualReg,
}, {
  label: 'Team',
  value: CourseKind.Team,
}, {
  label: 'Game',
  value: CourseKind.Game,
}];

export const CourseKindField = {edit: {...DropdownField.edit, type: 'dropdown', component: CourseKindPicker}, display: DropdownField.display, options: optionsForKinds(null)};

const athleticRegFeeKinds = [CourseKind.AthleticReg, CourseKind.AthleticIndividualReg];
export const AthleticsCourseKindField = {...CourseKindField, edit: {...CourseKindField.edit, kinds: athleticRegFeeKinds}, display: CourseKindField.display, options: optionsForKinds(athleticRegFeeKinds)};
