import * as Types from '../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerDiscountsQueryVariables = Types.Exact<{
  ownerId: Types.Scalars['ID'];
  ownerType: Types.Scalars['String'];
}>;

export type OrganizerDiscountsQuery = {
  discountDefinitions: Array<
    | {
        id: string;
        active: boolean;
        amount: number;
        code: string;
        format: Types.DiscountDefinitionFormat;
        kind: Types.DiscountKind;
        usesCount: number;
        scope: { courses: Array<string>; courseKinds: Array<Types.CourseKind> };
      }
    | null
    | undefined
  >;
};

export const OrganizerDiscountsDocument = gql`
  query OrganizerDiscounts($ownerId: ID!, $ownerType: String!) {
    discountDefinitions(ownerId: $ownerId, ownerType: $ownerType) {
      id
      active
      amount
      code
      format
      kind
      usesCount
      scope {
        courses
        courseKinds
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type OrganizerDiscountsArgs = MakeOptional<UseQueryArgs<OrganizerDiscountsQueryVariables, OrganizerDiscountsQuery>, 'query'>;

export function useOrganizerDiscountsQuery(options: OrganizerDiscountsArgs = {}) {
  return useQuery<OrganizerDiscountsQueryVariables, OrganizerDiscountsQuery>({ query: OrganizerDiscountsDocument, ...options });
}

export type OrganizerDiscountsOptions = ExecQueryOptions<OrganizerDiscountsQueryVariables>;

export function organizerDiscounts(options: OrganizerDiscountsOptions) {
  return executeQuery<OrganizerDiscountsQuery, OrganizerDiscountsQueryVariables>(OrganizerDiscountsDocument, options);
}

export type OrganizerDiscountsSelections = OrganizerDiscountsQuery['discountDefinitions'][0];

(OrganizerDiscountsDocument as any).additionalTypenames = ['DiscountDefinition'];
