import * as React from 'react'

import { StudentUtils } from 'app2/api';
import { SearchDropdown, SearchDropdownProps } from 'app2/views';

import { useStudentsQuery, StudentsSelections } from './generated';

export interface StudentDropdownProps extends SearchDropdownProps<StudentsSelections> {
  site?:string;
  company?:string;
  // excludes students that are enrolled in the specified course
  excludedCourses?:string[];
}

export function StudentDropdown(props:StudentDropdownProps) {
  const {site, excludedCourses, ...remaining} = props;

  function render() {
    return <SearchDropdown query={useStudentsQuery} queryVars={{site:props.site, company:props.company, excludedCourses: props.excludedCourses}} renderItem={renderStudent} {...remaining} />
  }

  function renderStudent(student:StudentsSelections) {
    return `${StudentUtils.getStudentName(student)}`
  }

  return render();
}
