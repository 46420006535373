import * as React from 'react'
import { debounce } from 'lodash-es';

import { Box, BoxProps, VBox } from '../Box';
import { VirtualTable, TableSection, TableSectionType, CellInfo } from '../virtualized';
import { contains, Point, Rect, getScrollParent, getScrollbarWidth, ScrollWatcher } from '../dom-utils';
import { Command, UndoManager, MultipleCommands } from '../undo';
import { PopupManager } from '../modal';
import { ErrorHandlerInterface, ErrorWithPath } from '../error';
import { FormModel, FormValidateOptions, mergeFieldComponentProps } from '../form';
import { Unsticky } from '../Unsticky';
import { MultiContext, MultiContextProvider, Mutex } from '../utils';

import { DataTableColumn, defaultColWidth, GetFilterOptions, OnSortFilter, defaultGetFilterOptions, defaultSortFilter, colId } from './column';
import { SelectionIterator, SelectionIteratorPredicate } from './SelectionIterator';
import { Selection } from './Selection';
import { MultipleSelection } from './MultipleSelection';
import { CellFactory } from './CellFactory';

import { TopLeftHeaderRenderer, TopLeftHeaderRendererProps, RowHeaderRenderer, ColHeaderRenderer, ColHeaderRendererProps, RowHeaderRendererProps, CellRenderProps, SelectionRenderer, SelectionRendererProps, cellCss } from './renderers';
import { ClipboardManager, SelectionManager, EditorManager, ColResizeManager, ColMoveManager, InvalidationManager } from './managers';
import { ObservableCollection, ObservableCollectionArray, ReadOnlyObservableCollectionArray, CollectionEvent, CollectionEventType } from './collection';
import { ChangeMap, FormCollection, EditableFormCollection, ReadonlyFormCollection } from './form-collection';
import { AppendRowCommand, RemoveRowCommand, ParseValues, setValuesNoop} from './commands';

export type DataCellPositionIds<P = any> = {colId:P, rowId:string | number};
export const defaultRowHeight = 38;
export type SelectionEventSource = React.MouseEvent | React.PointerEvent | MouseEvent | PointerEvent | React.KeyboardEvent | KeyboardEvent;

export interface DataTableProps<T> extends BoxProps {
  cols:DataTableColumn<T>[];
  data:T[] | ObservableCollection<T>;
  colHeaderHeight?:number;
  colHeader?:React.ComponentType<ColHeaderRendererProps>;//used as a default if none specified on DataTableColumn
  colHeaders?:boolean;
  defaultColWidth?:number;
  topLeftHeader?:React.ComponentType<TopLeftHeaderRendererProps>;
  rowHeader?:React.ComponentType<RowHeaderRendererProps>;
  rowHeaders?:boolean;
  rowHeaderWidth?:number;
  rowHeight?:((row:number) => number) | number;
  measuredRows?:boolean;
  cellRenderer?:React.ComponentType<CellRenderProps> | React.ReactElement<CellRenderProps>;// represents the outer part of the cell, not the field of a the cell
  colResize?:boolean;
  colMove?:boolean;
  cellStyle?:'default' | 'read-only' | 'editable';
  editable?:boolean;
  appendable?:boolean;//when in edit mode, dictates if new rows can be added
  focusable?:boolean;
  lockable?:boolean;
  hideable?:boolean;//can cols be hidden
  sortable?:boolean;//default for all columns
  filterable?:boolean;//default for all columns
  getFilterOptions?:GetFilterOptions<T>;//default for all columns
  sortFilterWhenEditable?:boolean;//overrides columns
  lockedCol?:number;
  rowSelection?:boolean;
  rowNumbers?:boolean;
  // because there might be a dependency relationshop between fields/columns
  // such as a master/detail relationship, that is not necessarily the order the columns
  // you can supply a paste row function that can transform any values before they are
  // set into the row's form
  parseRow?:ParseValues<T>;
  // when a new row is added, values will be copied from this
  defaultValues?:any;
  multipleSelection?:boolean;
  selection?:Selection | MultipleSelection | number[];
  selectionRenderer?:React.ComponentType<SelectionRendererProps> | React.ReactElement<SelectionRendererProps>;
  enterMovesSelection?:boolean;
  enterAddsRow?:boolean;//adds a row only in editable mode and on the last row
  atLeastOneEditableRow?:boolean;//ensures while in edit mode there's always at least 1 row
  fillWidth?:boolean;//if true, stretches the last column to fill the width of the scroll container
  none?:React.ReactNode;
  contextMenu?:React.ReactElement<{table?:DataTable}>;
  // normally the grid draws the cells visible within the
  // scrolling area.  however if for some reason the table
  // is forced into a css sticky component, it won't scroll
  // yet you may want it to scroll.  set unstickyX/Y to
  // make the grid shift itself left/top based on the scroll
  // position, to effectively make the grid not be sticky
  // while inside a sticky container
  unstickyX?:boolean;
  unstickyY?:boolean;
  // by default the table will position the locked rows
  // cols to be sticky at the 0,0 top left of the scrolling
  // area.  if however you have other sticky things above
  // and to the left of it, you can specify those dimensions
  // here so the table's locked row/cols don't scroll on 
  // top of or below those other sticky elements.
  stickyOffset?:Point;
  // if set, highlights in each cell if this text appears
  highlightText?:string;
  // if set, indicates that the table should show a line that indicates a
  // "page break" at that number of pixels
  pageWidth?:number;
  // indicates if vertical (top/bottom) scrollbar shadows should be rendered
  // to indicate there's more scrolling available
  scrollShadows?:boolean;
  // if present, when in edit mode, and new data is given to the
  // table, this will cause the table to keep rows that have been modified
  keepDirty?:boolean;
  // if present, the table will operate in group mode
  group?:{
    // for a grouping node, this is the attribute that has the open/close state
    //  - if the value is opened, the table will display a - sign
    //  - if the value is closed, the table will display a + sign
    //  - if there's no value then this isn't a grouping row
    state:string;
    // for a grouping node, this is the attribute that has the grouped value
    value:string;
    // for a grouping node, this is the count of items in the group
    count?:string;
    // color to use on a group row
    color?:string;
    // background to use for a group row;
    bg?:string;
    // height to use for group rows (by default uses standard row heights)
    height?:number;
    onToggleState: (row:any) => void | Promise<void>;
  }
  // events
  onRowReset?:(form:FormModel<T>) => void;//called to init a row when editing or if the values of a row are reset (such as from undo)
  onColResize?:(cols:DataTableColumn[], start:number, count:number, widthChange:number) => boolean | void;
  onColMove?:(cols:DataTableColumn[], start:number, count:number, destination:number) => boolean | void;
  onSortFilter?:OnSortFilter<T>;//default for all columns
  onViewChange?:(table:DataTable) => void;
  onSelectionChange?:(table:DataTable, selection:MultipleSelection, source?:SelectionEventSource) => void;
  onDataUpdate?:(table:DataTable) => void;
}

export interface DataTableState<T> {
  selections:MultipleSelection<T>;
  data:FormCollection<T>;
  allCols:DataTableColumn<T>[]; // does include hidden cols
  cols:DataTableColumn<T>[];// does not include hidden cols
  lockedCol:number;//set to -1 to clear
  focused?:boolean;
}

export type GroupRowInfo = {state:string, count:number};
export type RowInfo = GroupRowInfo | number;

export class DataTable<T = any> extends React.Component<DataTableProps<T>, DataTableState<T>> implements ErrorHandlerInterface {
  static defaultProps:Partial<DataTableProps<any>> = {
    colHeaderHeight: defaultRowHeight,
    colHeader: ColHeaderRenderer,
    colHeaders: true,
    defaultColWidth,
    rowHeaders: true,
    rowHeaderWidth: 82,
    rowHeight:defaultRowHeight,
    topLeftHeader: TopLeftHeaderRenderer,
    rowHeader: RowHeaderRenderer,
    colResize: true,
    colMove: true,
    editable: true,
    appendable: true,
    focusable: true,
    lockable: true,
    sortFilterWhenEditable: false,
    lockedCol: -1,
    rowSelection: true,
    rowNumbers: true,
    parseRow:setValuesNoop,
    multipleSelection: true,
    selectionRenderer:SelectionRenderer,
    enterMovesSelection: true,
    enterAddsRow: true,
    atLeastOneEditableRow: true,
    getFilterOptions: defaultGetFilterOptions,
    onSortFilter: defaultSortFilter
  }

  private _root:React.RefObject<HTMLDivElement>;
  private _table:React.RefObject<VirtualTable>;
  private _selectionManager:React.RefObject<SelectionManager>;
  private _scrollShadowTop:React.RefObject<HTMLDivElement>;
  private _scrollShadowBottom:React.RefObject<HTMLDivElement>;
  private _factory:CellFactory;
  private _data:FormCollection<any>;//mirrors data but we need access to it before react feels like giving access to state
  private _unfilteredData?:FormCollection<T>;// this is only used when using default sort/filter
  private _scroller:HTMLElement;
  private _scrollWatcher:ScrollWatcher;
  private _scrollWidth:number;
  private _scrollbarWidth:number;
  private _resizeObserver:ResizeObserver;
  private _filledLastColWidth:number;
  private _selections:MultipleSelection<T>;//shadows state but is updated synchronously
  private _updateManager:InvalidationManager;
  private _domFocus:(options:FocusOptions) => void;
  private _prevNoneMsgWidth:string;
  private _saveMutex:Mutex<boolean>;

  // only used when grouping, to map row ids
  // to row numbers, so that we don't include
  // grouping rows in the row count.  if the
  // value is a string its a group row and the value is
  // its grouping state
  private _groupRowInfo:RowInfo[];

  state:DataTableState<T>;

  constructor(props:DataTableProps<T>) {
    super(props);

    this._updateManager = new InvalidationManager(this);
    this.state = {selections: undefined, allCols:undefined, cols: undefined, data: undefined, lockedCol: Number.isFinite(props.lockedCol) ? props.lockedCol : -1};
    this._factory = new CellFactory(this);
    this._root = React.createRef<HTMLDivElement>();
    this._table = React.createRef<VirtualTable>();
    this._selectionManager = React.createRef<SelectionManager>();
    this._scrollShadowTop = React.createRef<HTMLDivElement>();
    this._scrollShadowBottom = React.createRef<HTMLDivElement>();
    this.state = {...this.state, ...this.updateColState(props)};
    this.state = {...this.state, ...this.updateDataState(props, this.state.cols, false)};
    this.state.selections = this.updateSelection(props).selections;
    this._selections = this.state.selections;
    this._saveMutex = new Mutex();
  }

  componentDidMount() {
    this._scroller = getScrollParent(this._root.current);

    if (this.props.scrollShadows) {
      this._scrollWatcher = new ScrollWatcher({onScroll: this.onScroll});
      this._scrollWatcher.watch(this._root.current);
    }

    this._scrollWidth = this._scroller.clientWidth;
    this._scrollbarWidth = getScrollbarWidth(this._scroller);
    this._resizeObserver = new ResizeObserver(this.onScrollerResize);
    this._resizeObserver.observe(this._scroller);

    // force an update because the first render will not render
    // anything because we need to mount to find the scroll parent
    this._updateManager.onReset();

    //@ts-ignore
    this.rootElement.testFields = this.testFields;
    //@ts-ignore
    this.rootElement.testValues = this.testValues;
    //@ts-ignore
    this.rootElement.testSelect = this.testSelect;
  }

  componentWillReceiveProps(nextProps:DataTableProps<T>) {
    if (this.element && this.element.focus != this.focus) {
      this._domFocus = this.element.focus.bind(this.element);
      this.element.focus = this.focus;
    }

    let updateLayout = this.props.rowHeight != nextProps.rowHeight;
    let updatedCols = false;
    let updatedState = false;
    let state:DataTableState<T> = {data: this.data, cols: this.state.cols} as DataTableState<T>;

    if (this.props.cols != nextProps.cols || this.props.editable != nextProps.editable || this.props.onRowReset !== nextProps.onRowReset || this.props.pageWidth != nextProps.pageWidth) {
      updateLayout = true;
      updatedCols = true;
      updatedState = true;
      state = Object.assign({}, state, this.updateColState(nextProps));
    }

    if (this.props.data != nextProps.data || this.props.editable != nextProps.editable) {
      updateLayout = true;
      updatedState = true;
      state = Object.assign({}, state, this.updateDataState(nextProps, state.cols, this.props.editable));
    }
    else
    if (updatedCols && nextProps.editable) {
      this.editableForm.updateFields(this.cols);
    }

    if (this.props.selection !== nextProps.selection) {
      const selections = this._selections;

      updatedState = true;
      state = Object.assign({}, state, this.updateSelection(nextProps));

      this._updateManager.onSelect(this._selections, selections);
    }

    const nextLockCol = Number.isFinite(nextProps.lockedCol)? nextProps.lockedCol : -1

    if (nextLockCol != this.state.lockedCol) {
      updatedState = true;
      state.lockedCol = nextLockCol;
    }

    if (this.props.highlightText != nextProps.highlightText) { 
      updateLayout = true;
    }

    if (updatedState) {
      this.setState(state);
    }

    if (updateLayout) {
      // reset the table and not create a new CellFactory because
      // reset on the table allows the cached measured heights to
      // be used while measuring takes place, preventing flashes
      // when the data didn't really change (despite getting a new data set,
      // which occurs when using urql which first returns a cached result
      // then a network response, that might be identical in value but
      // not in identity, and data comparisons on update props would
      // be way too expensive to check)
      this._updateManager.onReset();
    }
  }

  getNeedsBlankRow(props:Readonly<DataTableProps<T>>) {
    return props.atLeastOneEditableRow && props.editable && this._unfilteredData.length < 1 && this._selections != null;
  }

  get needsBlankRow() {
    return this.getNeedsBlankRow(this.props);
  }

  get tableIsScroller() {
    return this._scroller == this.rootElement;
  }

  componentDidUpdate(prevProps: Readonly<DataTableProps<T>>): void {
    this.appendBlankIfNeeded();

    if (this.renderNone && this._prevNoneMsgWidth != this.noneMsgWidth) {
      this.deferredUpdate();
    }
  }

  componentWillUnmount() {
    this._scrollWatcher?.unwatch?.();
    this._scrollWatcher = null;

    this._resizeObserver.disconnect();
    this._resizeObserver = null;

    this.data.unobserve?.(this.onDataUpdate);
    this.data.release();
  }

  deferredUpdateTimeout:any;

  deferredUpdate() {
    this.clearDeferredUpdate();

    this.deferredUpdateTimeout = setTimeout(() => {
      this.deferredUpdateTimeout = null;

      if (this.rootElement) {
        this.forceUpdate();
      }
    }, 100);
  }

  clearDeferredUpdate() {
    if (this.deferredUpdateTimeout) {
      clearTimeout(this.deferredUpdateTimeout);
    }
  }

  updateColState(newProps:Partial<DataTableProps<T>>) {
    const allCols = (newProps.cols || []).map(col => Object.assign({}, col)).filter(col => colId(col));
    const cols = allCols.filter(col => !col.hidden);
    this.computeFilledLastCol(cols);
    this.state.cols = cols;
    this.selections?.makeValid();
    this.paginate();

    return {allCols, cols};
  }

  updateDataState(newProps:DataTableProps<T>, cols:DataTableColumn<T>[], cancelChanges:boolean, updateUnfilteredData:boolean = true) {
    if (cancelChanges) {
      this.cancelChanges();
    }

    const changes = this.props.keepDirty && this.props.editable && this.editableForm ? this.getChanges() : undefined;

    if (this.data) {
      this.data.unobserve?.(this.onDataUpdate);
      this.form.release();
    }

    newProps = newProps || {} as DataTableProps<T>;

    const newData = newProps.data || [];
    const data = Array.isArray(newData) 
      ? newProps.editable ? new ObservableCollectionArray<T>(newData, 'id') : new ReadOnlyObservableCollectionArray<T>(newData, 'id')
      : newData;

    this._data = newProps.editable 
      ? new EditableFormCollection(this, cols, data) as FormCollection<T>
      : new ReadonlyFormCollection(this, data) as FormCollection<T>

    if (updateUnfilteredData) {
      this._unfilteredData =  this._data;
    }

    if (newProps.editable && changes) {
      this.mergeChanges(changes);
    }
    
    if (this.getNeedsBlankRow(newProps)) {
      this.appendBlankIfNeeded();
    }

    const group = this.props.group;
    this._groupRowInfo = undefined;
  
    if (this.props.group) {
      const itemCount = this._data.length;
      let rowIndex = 0;

      this._groupRowInfo = Array(itemCount);

      for (let itemIndex = 0; itemIndex < itemCount; ++itemIndex) {
        // todo this might be wrong because its unclear to me right now if the
        // open/closing of a group row will cause a call to updateDataState
        const row = this._data.getItem(itemIndex);
        const groupState = row[group.state];
        this._groupRowInfo[itemIndex] = groupState ? {state:groupState, count:row[group.count]} :rowIndex++;
      }
    }

    this.data.observe(this.onDataUpdate);
    this._updateManager.onReset();

    this.makeSelectionValid(true);

    return {cols, data: this._data};
  }

  // attempts to merge changes to new data that
  // were updated after a save is causing this refresh of data

  mergeChanges(changes:ChangeMap<T>) {
    for (const id in changes) {
      const change = changes[id];
      const row = this.rowIdToPos(id);

      if (change.type == CollectionEventType.delete && row != -1) {
        this.data.remove(row);
      }
      else
      if (change.type == CollectionEventType.create) {
        this.data.insert(change.pos, change.item);
      }
      else
      if (change.type == CollectionEventType.update && row != -1) {
        this.data.reset(row, {values:change.item as T, dirty: true, updatedAt: change.updatedAt, silent: true});
      }
    }
  }

  // a helper that will mark dirty rows clean on a successful
  // save.  the callback should return true for a successful save
  // otherwise return false or throw an error...this can be used
  // in combination with the keepDirty prop to ensure changes
  // are lost during asynchronous saves and server updates
  //
  // this will serialize saves and not allow multiple saves at
  // a time.  this is important to avoid resaving the same data
  // as we currently keep just one change set.  also, it avoids
  // an issue with urql not refiring queries if another refire
  // is already happening.

  async save(cb:(changes:ChangeMap<T>) => Promise<boolean>) {
    return this._saveMutex.withLock(async () => {
      const changes = this.getChanges();
      const result = await cb(changes);

      if (!!result && this.props.editable) {
        for (const id in changes) {
          const change = changes[id];
          const pos = this.rowIdToPos(id);
          
          if (pos != -1) {
            const form = this.editableForm.getForm(pos);

            if (form.updatedAt == change.updatedAt) {
              form.dirty = false;
              form.updatedAt = undefined;
            }
          }
        }
      }

      return result;
    });
  }

  updateSelection(newProps:Partial<DataTableProps<T>>) {
    const incomingSelection = newProps.selection;
    let selections:MultipleSelection;

    if (!incomingSelection) {
      selections = new MultipleSelection(this);
    }
    else
    if (Array.isArray(incomingSelection)) {
      selections = new MultipleSelection(this, incomingSelection.length ? [] : undefined);
      selections.selectRows(incomingSelection);
    }
    else
    if (incomingSelection instanceof Selection) {
      selections = new MultipleSelection(this, incomingSelection.clone()) 
    }
    else {
      selections = incomingSelection.clone();
    }

    this._selections = selections;
    return {selections};
  }

  get numRows():number {
    return this.data?.length || 0;
  }

  get numCols():number {
    return this.cols?.length || 0;
  }

  get colHeaderCount():number {
    return this.props.colHeaders ? 1 : 0;
  }

  get rowHeaderCount():number {
    return this.props.rowHeaders ? 1 : 0;
  }

  // does not include hidden columns
  get cols():DataTableColumn<T>[] {
    return this.state.cols;
  }

  get allCols():DataTableColumn<T>[] {
    return this.state.allCols;
  }

  updateCols(cols:DataTableColumn<T>[]) {
    // updateCols is called by move and resize manager to update col widths and
    // position however it represents only the visible columns. so we need to
    // map the incoming list to the master list.  since in the incoming list
    // order matters (such as it contains columns that we're moved by the user)
    // we just append hidden columns to the end.
    const index = new Map();
    cols.forEach(col => index.set(colId(col), col));

    const all = [...cols, ...this.state.allCols.filter(col => !index.has(colId(col)))];
  
    this.setState(this.updateColState({cols: all}));
    this._updateManager.onReset();
  }

  paginate() {
    if (!this.props.pageWidth) {
      return;
    }

    let width = 0;

    this.cols.forEach((col, index) => {
      const colWidth = col.width || this.defaultColWidth;

      if (width + colWidth > this.props.pageWidth) {
        width = 0;
        this.cols[index].break = true;
      }
      else {
        width += colWidth;
        this.cols[index].break = false;
      }
    })
  }

  // generally you shouldn't use this setter, you should
  // be setting data via props...this is for the default
  // sort/filter methods which can't update props
  set data(data:T[] | ObservableCollection<T>) {
    const newState = this.updateDataState({data, editable: this.props.editable} as DataTableProps<T>, this.state.cols, false, false);
    this.setState({...newState});
  }

  get data():FormCollection<T> & ObservableCollection<T> {
    return this._data;
  }

  // if using default sort/filter this represents the data set
  // without the sort/filter, otherwise it will be the same as data
  get unfilteredData() {
    return this._unfilteredData;
  }

  get editableData():ObservableCollection<T> {
    return this._data as any;
  }

  get form():FormCollection<T> {
    return this._data as any;
  }

  get editableForm():EditableFormCollection<T> {
    return this._data as any;
  }

  // this gets a "form" field that has copy/paste
  // or format/parse methods.  field differs 
  // from column in that in edit mode, the fields
  // maintain state per row (which is important
  // for the disabled property to be correct).
  // it will try the default field, if that doesn't
  // have the needed methods it will "flatten"
  // the field (flattening component, display and 
  // edit levels) for the current table editable
  // mode and if that doesn't work it will try 
  // the opposite editable mode.
  getFieldForCopyPaste(rowNo:number, colNo:number, forPaste:boolean) {
    const col = this.cols[colNo];
    const field = this.form.getField([rowNo], col.name);
    let fieldComponent:DataTableColumn = mergeFieldComponentProps({...col, disabled: field.disabled, readOnly: field.readOnly}, this.editable);
    const hasMethod = !forPaste 
      ? fieldComponent.copy || fieldComponent.format
      : fieldComponent.paste || fieldComponent.parse

    if (!hasMethod && this.editable) {
      fieldComponent = mergeFieldComponentProps(field, false);
    }

    return fieldComponent;
  }

  get allItems():T[] {
    const sel = new Selection(this);
    sel.selectAll();

    return sel.items;
  }

  get selectedItems() {
    return this.selections.selectedItems;
  }
  
  get selection() {
    const sel = this.selections.selection;

    return sel ? sel : new Selection(this);
  }

  set selection(selection:Selection) {
    this.setSelection(selection);
  }

  get selections():MultipleSelection<T> {
    return this._selections;
  }

  set selections(selections:MultipleSelection<T>) {
    // don't bother trying to compare old and new because since its
    // multiple selections on both sides comparing would have to be
    // optimized (such as sorting) to avoid O(N^2)
    this.setSelectionInternal(selections, false);
  }

  select(selection:Selection | Point, makeVisible:boolean = false) {
    this.setSelection(selection, makeVisible);
  }

  setSelection(sel:Selection | Point, makeVisible:boolean = false, source?:SelectionEventSource) {
    const selection = sel instanceof Selection ? sel : new Selection(this, sel);
    let selections = new MultipleSelection(this, selection);

    this.setSelectionInternal(selections, true, source);

    if (makeVisible) {
      this.makeVisible(selection.anchor);
    }
  }

  setSelectionInternal(selections:MultipleSelection, compare:boolean = true, source:SelectionEventSource = null) {
    let state:any;
    const old = this._selections;

    if (!compare || (this.props.selection === undefined && (!this.selection || !this.selection.equal(selections.selection)))) {
      this._selections = selections;
      state = {selections};
    }

    // always dispatch an event since the event source can change
    if (this.props.onSelectionChange) {
      this.props.onSelectionChange(this, selections, source);
    }

    // we need to invalidate and set state last/after dispatching
    // a selection change event so that if the user of the list
    // is doing anything with the selection (like List does) that
    // everything is redrawn together
    this._updateManager.onSelect(old, selections);

    if (state) {
      this.setState(state);
    }
  }

  // if there are any whole row or cols select
  // this just selects a single cell
  // has no impact if a single cell is selected
  clearSelection() {
    const sel = this.selection.clone();
    sel.collapse()

    this.setSelection(sel);
  }

  makeSelectionValid(noStateChange:boolean = false) {
    if (!this._selections) {
      return;
    }

    if (this.selection.valid) {
      return;
    }

    if (noStateChange) {
      // this specifically bypasses updating state
      // and broadcasting a selection change for cases
      // where in the middle of a state change, rendering, etc.
      this.selection.makeValid();
    }
    else {
      this.selection = this.selection.clone().makeValid();
    }
  }

  makeVisible(cellPos:Point) {
    this.selectionManager?.makeVisible(cellPos);
  }

  makeSelectedVisible() {
    if (!this.selection) {
      return;
    }

    this.makeVisible(this.selection.focus);
  }

  setOversizedCellSize(width:number, height:number) {
    return this.selectionManager?.setOversizedCellSize(width, height);
  }

  posToIds(pos:Point):DataCellPositionIds {
    const colId_ = colId(this.cols[pos.col]);
    const rowId = this.data.getId ? this.data.getId(pos.row) : pos.row;

    return {colId:colId_, rowId};
  }

  idsToPos(pos:DataCellPositionIds, includeHiddenCols:boolean = false) {
    const colIndex = (includeHiddenCols ? this.allCols : this.cols).findIndex(col => colId(col) == pos.colId) || 0;
    const rowIndex = this.data.getIndex ? this.data.getIndex(pos.rowId) : pos.rowId as number;
    
    return new Point(colIndex, rowIndex);
  }

  rowPosToId(row:number):number | string {
    return this.posToIds(new Point(0, row)).rowId;
  }

  rowIdToPos(rowId:number | string):number {
    return this.idsToPos({rowId, colId: colId(this.cols[0])}).row;
  }

  getCellInfo(rowPos:number, colOrIdOrIndex:DataTableColumn<T> | string | number) {
    const col = this.getColumn(colOrIdOrIndex);

    return this.form.getInfo([rowPos], col.name);
  }

  get selectionManager():SelectionManager {
    return this._selectionManager.current;
  }

  get visibleTopLeft() {
    return this.virtualTable.visibleTopLeft;
  }

  get virtualTable() {
    return this._table.current;
  }

  get scroller() {
    return this._scroller;
  }

  get rootElement():HTMLElement {
    return this._root?.current;
  }

  get element():HTMLElement {
    return this.virtualTable?.middleMiddleRef?.current?.element?.current;
  }

  get editable() {
    return this.props.editable;
  }

  get canSortFilter() {
    return this.props.sortFilterWhenEditable || !this.editable;
  }

  get defaultColWidth() {
    return this.props.defaultColWidth || 100;
  }

  rowHeight(row:number):number {
    const contentRow = row - this.colHeaderCount;

    return row < this.colHeaderCount 
      ? this.props.colHeaderHeight 
      : this.props.group?.height && typeof this._groupRowInfo[contentRow] == 'object' 
        ? this.props.group?.height
        : typeof this.props.rowHeight === 'function' 
          ? this.props.rowHeight(contentRow) 
          : this.props.rowHeight;
  }

  get rowHeaderColWidth() {
    return !this.props.rowHeaders ? 0 : this.props.rowNumbers ? this.props.rowHeaderWidth : this.props.rowHeaderWidth - 43;
  }

  colWidth(col:number):number {
    if (col < this.rowHeaderCount) {
      return this.rowHeaderColWidth;
    }

    const colNo = col - this.rowHeaderCount;
    const isLast = colNo == this.cols.length - 1;
    const width = this.props.fillWidth && isLast ? this._filledLastColWidth : this.cols[colNo]?.width || this.defaultColWidth;

    // width of -1 means use the entire scrollable area, less scrollbar width
    if (width != -1) {
      return width;
    }

    // this can happen when the vertical scrollbar hides
    if (this._scroller && this._scrollWidth != this._scroller.clientWidth) {
      this._scrollWidth = this._scroller.clientWidth;
      this._updateManager.onResizeCol(this.cols.length);
    }

    return this._scrollWidth || 0;
  }

  getColumnIndex(name:string):number {
    return this.cols.findIndex(col => colId(col) == name || col.label == name);
  }

  // getColumn(col:number):DataTableColumn<T>;
  // getColumn(id:string):DataTableColumn<T>;
  // getColumn(col:DataTableColumn<T>):DataTableColumn<T>;
  getColumn(colOrIdOrIndex:DataTableColumn<T> | string | number):DataTableColumn<T> {
    if (typeof colOrIdOrIndex === 'object') {
      return colOrIdOrIndex;
    }

    if (typeof colOrIdOrIndex === 'number') {
      return this.cols[colOrIdOrIndex];
    }

    if (typeof colOrIdOrIndex !== 'string') {
      return null;
    }

    const colIdOrName = colOrIdOrIndex.split('.')[0];

    return this.allCols.find(col => (colId(col) == colIdOrName) || col.label == colIdOrName);
  }

  // this will find the first column that has a matching name
  // keep in mind that name is not guaranteed to be unique, id
  // is used for that.  name is the name in the form which can repeat.
  getColumnByName(name:string):DataTableColumn<T> {
    return this.allCols.find(col => col.name == name);
  }

  setVisibleCols(ids:string[]) {
    const cols = this.allCols.slice();
    cols.forEach(c => {
      c.hidden = ids.indexOf(colId(c)) == -1;
    })

    this.updateCols(cols);
    this.props.onViewChange?.(this);
    this.forceUpdate();
  }

  showCol(id:string) {
    this.setColVisibility(id, true);
  }

  hideCol(id:string) {
    this.setColVisibility(id, false);
  }

  setColVisibility(id:string, visible:boolean) {
    const cols = this.allCols.slice();
    const col = cols.findIndex(c => colId(c) == id);
    cols[col].hidden = !visible;

    this.updateCols(cols);
    this.props.onViewChange?.(this);
    this.forceUpdate();
  }

  get lockedCol() {
    return this.state.lockedCol;
  }

  set lockedCol(lockedCol:number) {
    this.setState({lockedCol}, () => this.props.onViewChange?.(this));
  }

  setColFilter(id:string, filter:any[], clearIfAllSelected:boolean = true) {
    const index = this.allCols.findIndex(c => colId(c) == id);
    const col = this.allCols[index];
    col.filter = filter;

    // this prevents not blank from being used in combination
    // with specific values because otherwise it looks broken
    // because not blank will always include all values
    if (clearIfAllSelected && col.filter?.length > 1) {
      const notBlank = col.filter.indexOf("NOT NULL");

      if (notBlank == col.filter.length - 1) {
        col.filter = ["NOT NULL"];
      }
      else if (notBlank != -1) {
        col.filter.splice(notBlank, 1);
      }
    }

    const onSortFilter = col.onSortFilter || this.props.onSortFilter;
    onSortFilter(this, col, col.sort, col.filter);
    this.props.onViewChange?.(this);
  }

  onMouseDown = (event:React.MouseEvent) => {
    if (event.target != this._root.current) {
      return;
    }

    this.focus();
    event.stopPropagation();
    event.preventDefault();
  }

  onFocus = () => {
    this.setState({focused:true});
  }

  onBlur = (event:React.FocusEvent) => {
    if (contains(this.rootElement, event.relatedTarget as HTMLElement)) {
      return;
    }

    this.setState({focused:false});
  }

  focus = () => {
    const element = this.element;

    // if the table is empty there won't be a node
    if (!this._domFocus && !element) {
      return;
    }

    // set the document selection as well
    // as focus else the browser won't generate
    // copy/paste events
    if (element) {
      const range = new Range();
      range.setStart(element, 0);
      range.setEnd(element, 0);
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(range);
    }

    // we monkey patch element focus to come to our focus method
    // so that we can always ensrue preventScroll true no matter
    // who is the caller of focus (such as reactjspopup).  see T2313.
    this._domFocus ? this._domFocus({preventScroll: true}) : this.element.focus({preventScroll: true})
  }

  append(item?:T):void {
    if (!this.editable || !this.props.appendable) {
      return;
    }

    UndoManager.instance.push(new AppendRowCommand(this, item));
  }

  remove(row:number):void {
    if (!this.editable) {
      return;
    }

    UndoManager.instance.push(new RemoveRowCommand(this, [row]));

    this.appendBlankIfNeeded();
  }

  appendBlankIfNeeded() {
    if (this.needsBlankRow) {
      // adds a row but doesn't add it to the undo stack
      new AppendRowCommand(this).do();
    }
  }

  removeSelected() {
    if (!this.editable) {
      return;
    }

    // selections is if rows selected by checkbox, selection is if selected via cell selection, this checks both
    const rows = this.selections.selectedRows.length ? this.selections.selectedRows : this.selection.rows;

    let command:Command = new RemoveRowCommand(this, rows);

    if (rows.length == this.data.length) {
      command = new MultipleCommands([new AppendRowCommand(this), command]);
    }

    UndoManager.instance.push(command);
  }

  // for undo, restores a row to what the passed in snapshot is)
  restore(row:number, item:Partial<T>) {
    const fields = this.editableForm.getFields(row);

    item = {...item};
    //@ts-ignore - for some reason TS doesn't detect the type of values in fields
    Object.values(fields).forEach(field => item[field.name] = item[field.name] === undefined ? null : item[field.name]);

    this.form.setValues([row], item);
  }

  // validates editted rows - useful for newly added rows
  // where validations don't run on cells until the user
  // has touched them (to avoid showing errors as soon as the
  // the row is added)
  async presubmit(options:FormValidateOptions = {skipValidatingEmpty: true}):Promise<boolean> {
    const success = await this.editableForm.presubmit(options);

    if (success) {
      return true;
    }

    this.makeFirstErrorVisible();
    
    return false;
  }

  cancelChanges() {
    if (!this.props.editable) {
      return;
    }

    this.editableForm?.cancelChanges();
  }

  getItems(onlyChanged?:boolean, emptyRows:boolean = false):T[] {
    return this.editableForm.getItems(onlyChanged, emptyRows);
  }
  
  getChanges(removeEmptyNewRows:boolean = true) {
    return this.editableForm.getChanges(removeEmptyNewRows);
  }

  get dirty() {
    return this.editableForm.dirty;
  }

  markClean() {
    return this.editableForm.markClean();
  }
  
  isNewRow(pos:number) {
    return this.editableForm.isNewRow(pos);
  }

  getRowNumberOrGroupState(pos:number) {
    return this._groupRowInfo ? this._groupRowInfo[pos] : pos;
  }
  
  getRecordErrors(row:number) {
    return this.editableForm.getRecordErrors(row);
  }

  handleErrors(errors:ErrorWithPath[], clearPrevious?:boolean):ErrorWithPath[] {
    const unhandled = this.form.handleErrors(errors, clearPrevious);

    this.makeFirstErrorVisible();

    return unhandled;
  }

  clearErrors() {
    return this.form.clearErrors();
  }

  findFirstError():Point {
    return this.find(DataTable.cellHasError);
  }

  makeFirstErrorVisible() {
    const firstError = this.findFirstError();

    if (firstError) {
      this.makeVisible(firstError);
    }
  }

  render() {
    this.clearDeferredUpdate();
    this.makeSelectionValid(true);
    
    const {cols, data, colHeaderHeight, colHeader, colHeaders, defaultColWidth, topLeftHeader, rowHeader, rowHeaders, rowHeaderWidth, rowHeight, measuredRows, cellRenderer, colResize, colMove, cellStyle,
      editable, appendable, focusable, lockable, hideable, sortable, filterable, getFilterOptions, sortFilterWhenEditable, lockedCol, onSortFilter, onViewChange, onDataUpdate,
      rowSelection, rowNumbers, parseRow, defaultValues, multipleSelection, selection, selectionRenderer, enterMovesSelection, enterAddsRow, atLeastOneEditableRow, fillWidth, none, contextMenu, unstickyX, unstickyY, stickyOffset, highlightText, pageWidth,
      onRowReset, onColResize, onColMove, onSelectionChange, scrollShadows, keepDirty, group,
      borderRadius, border, borderColor, ...rest} = this.props;

    return <div style={{position: 'relative'}}>
      {this.renderVisibleBorder()}
      {this.renderDropdownScrollShadows()}
      <MultiContextProvider<DataTableContext<T>> dataTable={this}>
        <Box ref={this._root} position='relative' width='min-content' borderRadius='standard' data-test='TableBody' className={this.state.focused ? 'hr-table-focus' : undefined} onMouseDown={this.onMouseDown} onFocus={this.onFocus} onBlur={this.onBlur} css={cellCss} {...rest}>
          <Unsticky horizontal={unstickyX} vertical={unstickyY}>
            <VirtualTable ref={this._table} factory={this._factory} headerCols={this.colHeaderCount + this.state.lockedCol + 1} headerRows={this.rowHeaderCount} measuredRows={measuredRows} 
              onDimensionsChanged={this.onDimensionsChanged} onContextMenu={this.onContextMenu} stickyOffset={stickyOffset} style={{minHeight:this.props.minHeight as string}}
              renderSection={this.renderTableSection} />
          </Unsticky>
          {this.renderNoneMessage()}
        </Box>
      </MultiContextProvider>
    </div>
  }

  renderDropdownScrollShadows() {
    if (!this.props.scrollShadows) {
      return;
    }

    return <>
      <div ref={this._scrollShadowTop} style={{position:'absolute', left:0, zIndex:100, width: '100%', height: '20px', top:0, pointerEvents:'none', background: "linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.07) 50%, rgba(0, 0, 0, .2) 100%)"}} />
      <div ref={this._scrollShadowBottom} style={{position:'absolute', left:0, zIndex:100, width: '100%', height: '20px', bottom:0, pointerEvents:'none', background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.07) 50%, rgba(0, 0, 0, .2) 100%)"}} />
    </>
  }

  renderTableSection = (section:TableSection, style:any, children:React.ReactElement) => {
    const ref = section.type === TableSectionType.middleMiddle ? this._selectionManager : undefined;
    children = React.cloneElement(children, {tabIndex:this.props.focusable ? 0 : undefined, style:{outline:'none'}});

    children = <ClipboardManager table={this} section={section}>{children}</ClipboardManager>;

    if (this.props.colResize) {
      children = <ColResizeManager table={this} section={section}>{children}</ColResizeManager>;
    }

    if (this.props.colMove) {
      children = <ColMoveManager table={this} section={section}>{children}</ColMoveManager>;
    }

    children = <SelectionManager ref={ref} style={!this.props.editable ? style : undefined} table={this} section={section}>{children}</SelectionManager>;

    if (this.props.editable) {
      children = <EditorManager style={style} table={this} section={section}>{children}</EditorManager>;
    }

    return children;
  }

  renderVisibleBorder() {
    // the border is rendered here instead of as css on the root because its
    // desired to have the vertical borders always visible regardless of scroll position
    // if we put them on the root they'd scroll off when scrolling horizontally
    const { borderRadius, border, borderColor } = this.props;
    
    // if the table is contained by something scrolling, we want the height
    // to be the tables full height, otherwise if the table is the scroller
    // then we want the border to be the visible height
    const root = this.rootElement;
    const height = this.tableIsScroller 
      ? root?.getBoundingClientRect().height
      : Math.max(this._table?.current?.dimensions?.height, root ? parseFloat(window.getComputedStyle(root).minHeight) || 0 : 0);

    // because of how the locked table headers work we need to render a second border
    // that's above the locked headers, because the main border has to be below
    // the locked headers such that the cell editor will be above the border (and
    // the editor has to be below the locked headers)

    // zIndex 1 for the border is important
    // - we need a zIndex to be above normal content so that
    //   the borders for dropdowns show T1601
    // - but we want it to be below the cell editor in a table (T1856)
    //   so the border doesn't appear above the editor in blur mode (zIndex 9)
    // - but we want it to also be behind focused cells in readonly table (T1972)
    // - this also relies on the dropdowns using fixed positioning

    return <Box position='relative'>
      <Box zIndex={1} position='absolute' width='100%' top='0px' height={height + 'px'} pointerEvents='none' borderRadius={borderRadius} border={border} borderColor={borderColor} />
      {this.rowHeaderCount && this.colHeaderCount && this.data.length ? <Box zIndex={30} position='absolute' width={this.colWidth(0)} top={this.rowHeight(0)} height={(height - this.rowHeight(0)) + 'px'} pointerEvents='none' borderRadius={borderRadius} borderLeft={border} borderBottom={border} borderColor={borderColor} /> : ''}
    </Box>
  }

  renderNoneMessage() {
    if (!this.renderNone) {
      return;
    }

    this._prevNoneMsgWidth = this.noneMsgWidth;

    const rowHeaderHeight = this.rowHeight(0);
    const minHeight = Math.max((parseFloat(this.props.minHeight as string) || 0) - rowHeaderHeight, 20);
    
    return <VBox position='absolute' minHeight={minHeight + 'px'} hAlign='center' vAlign='center' left={0} top={rowHeaderHeight} width={this.noneMsgWidth}><VBox text='subtitle2' position='sticky' top='200px' margin='$8' textAlign='center'>{this.props.none}</VBox></VBox>;
  }

  get renderNone() {
    return !(!this.props.none || this.data.length || !this._scroller);
  }

  get noneMsgWidth() {
    return Math.min((this._scroller.getBoundingClientRect().width - this._scrollbarWidth), this._table.current?.element?.current?.getBoundingClientRect()?.width) + 'px';
  }

  getCellFromEvent(event:React.MouseEvent<HTMLElement> | MouseEvent):CellInfo {
    return this.virtualTable.getCellFromEvent(event);

  }

  getCellCoordinates(cell:Point):Rect {
    return this.virtualTable.getCellCoordinates(cell);
  }

  onDataUpdate = (event:CollectionEvent<T>) => {
    this._updateManager.onCollectionEvent(event);

    if (event.type != CollectionEventType.reset) {
      this.debouncedOnDataUpdate();
    }
  }

  debouncedOnDataUpdate = debounce(() => {
    this.props.onDataUpdate?.(this);
  }, 100);

  onScroll = () => {
    if (!this._scrollShadowTop.current) {
      return;
    }

    const visible = this._scroller.clientHeight;
    const top = this._scroller.scrollTop;
    const height = this._scroller.scrollHeight;
    const bottom = top + visible;

    this._scrollShadowTop.current.style.opacity = (top > visible ? 1 : top / visible).toString();
    this._scrollShadowBottom.current.style.opacity = (height - bottom > visible ? 1 :  (height - bottom) / visible).toString();
  }

  onScrollerResize = () => {
    this.computeFilledLastCol();
  }

  onDimensionsChanged = () => {
    // ensures the visible border changes as the tables
    // dimensions change
    this.deferredUpdate();
  }

  computeFilledLastCol(cols:DataTableColumn<T>[] = this.state.cols) {
    if (!this.props.fillWidth || !cols?.length) {
      return;
    }

    let filledLastCol = cols[cols.length - 1].width || this.defaultColWidth;
    this._scrollWidth = this._scroller?.clientWidth || 0;

    const sumOfColWidths = cols.reduce((total, col) => total + (col.width || this.defaultColWidth), -filledLastCol);
    filledLastCol = Math.max(filledLastCol, this._scrollWidth - Math.max(0, (this.rowHeaderColWidth + sumOfColWidths)));

    if (this._filledLastColWidth != filledLastCol) {
      this._filledLastColWidth = filledLastCol;
      this._updateManager.onResizeCol(cols.length - 1);
    }
  }

  testFields = () => {
    return this.cols.map(col => typeof col.label == 'string' ? col.label : col.name);
  }

  testValues = () => {
    const sel = new Selection(this);

    sel.selectAll();
    return sel.formattedValues;
  }

  testSelect = (row:number | string, col:string, colLabel?:string) => {
    const rowPos = Number(row);
    let colPos = this.getColumnIndex(col);

    if (colPos == -1) {
      colPos = this.getColumnIndex(colLabel);
    }

    // console.log('selecting', rowPos, colPos);

    if (colPos == -1) {
      console.log('col not found', col, ', cols avail', this.cols.map(col => colId(col)).join(', '));
    }

    this.select(new Point(colPos, rowPos), true);
  }

  onContextMenu = (event:React.MouseEvent) => {
    if (!this.props.contextMenu) {
      return;
    }

    if (!this.selection.rows.length) {
      return;
    }

    event.preventDefault();

    const cell = document.elementFromPoint(event.pageX, event.pageY) as HTMLElement;
    const menu = React.cloneElement(this.props.contextMenu, {table: this});
    PopupManager.add(menu, {closeOnClick: true, closeOnDocumentClick: true, closeOnEscape: true, anchor: cell});
  }

  findText(what:string, start?:Point, allowWrap:boolean = true):Point {
    what = what.toLocaleLowerCase();

    if (!start) {
      start = this.selection.topLeft;
      const sel = this.selection.clone();
      if (sel.atEnd) {
        sel.reset();
      }
      else {
        sel.moveTabRight();
      }

      start = sel.topLeft;
    }

    function cellHasText(iterator:SelectionIterator) {
      const value = iterator.selection.formattedValue;
      const contains = value.toLocaleLowerCase().indexOf(what) != -1;

      return contains;
    }
  
    const pos = this.find(cellHasText, start);

    return pos || !allowWrap ? pos : this.findText(what, new Point(0, 0), false);;
  }

  find(predicate:SelectionIteratorPredicate, prev?:Point):Point {
    const sel = new Selection(this);
    sel.selectAll();

    return sel.iterate(predicate, prev);
  }

  static cellHasError<T>(iterator:SelectionIterator) {
    if (iterator.col == 0) {
      if (iterator.table.form.getRecordErrors(iterator.row)?.length) {
        return true;
      }
    }

    const info = iterator.table.form.getInfo([iterator.row], iterator.table.cols[iterator.col].name);

    if (info.errors.length) {
      return true;
    }

    return false;
  }
}


export interface DataTableContext<T> {
  dataTable:DataTable<T>;
}

export function useDataTable<T = any>() {
  return React.useContext<DataTableContext<T>>(MultiContext).dataTable;
}
