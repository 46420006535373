import { colId, DataTableColumn } from '../column';

import { ExpressionNode } from './ExpressionNode';
import { filterToString } from './filterToString';
import { parse } from './filter';

export class FilterParser {
  cols:DataTableColumn[];

  constructor(cols:DataTableColumn[]) {
    this.cols = cols
  }

  parse(input:string, def?:any) {
    if (!input) {
      return def;
    }

    try {
      const result = parse(input);
      this.resolveColumns(result);

      return result;
    }
    catch(e) {
      if (!def) {
        throw e;
      }

      return def;
    }
  }
  
  validate(input:string) {
    if (!input) {
      return true;
    }

    try {
      parse(input);
      return true;
    }
    catch(e) {
      if (e.message.startsWith('Expected')) {
        return 'Invalid filter';
      }
  
      return e.message;
    }
  }

  toString(parsed:ExpressionNode) {
    return filterToString(parsed, this.cols);
  }
  
  findColId = (colName:string) => {
    const found = this.cols.find(c => c.label.toString().localeCompare(colName, undefined, { sensitivity: 'accent' }) == 0);
  
    if (!found) {
      throw new Error("Invalid column name: " + colName);
    }
  
    return colId(found);
  }

  resolveColumns(expr: ExpressionNode):void {
    if (expr.type == 'Comparison') {
      expr.left = this.findColId(expr.left);
    }
    else
    if (expr.type == 'RepeatingLogical') {
      expr.expressions.forEach(e => this.resolveColumns(e));
    }
    else
    if (expr.type == 'Logical') {
      this.resolveColumns(expr.left);
      this.resolveColumns(expr.right);
    }
    else
    if (expr.type == 'Group') {
      this.resolveColumns(expr.expr);
    }
  }
}
