import * as React from 'react';

import { Beta } from 'app2/views/shared-public';
import { VBox } from 'app2/components';

import { SiteRouteParams } from '../organizerRoutes';
import { Discounts } from '../shared';

import { SchoolFee } from './SchoolFee';
import { Stripe } from './Stripe';
import { Donations } from './Donations';
import { Tax } from './Tax';
import { PriceTiers } from './PriceTiers';
import { Proration } from './Proration';
import { PayoutConfig } from './PayoutConfig';
import { useParams } from 'react-router';

export function PaymentTools() {
  const { site: siteId } = useParams<SiteRouteParams>();
  
  return (
    <VBox>
      <SchoolFee />
      <Stripe />
      <Donations />
      <Tax />
      <Discounts ownerId={siteId} ownerType="site" />
      <Beta position='top'><PriceTiers /></Beta>
      <Proration />
      <PayoutConfig />
    </VBox>
  );
}
