import { Course, CourseStatus, CourseUtils, DeepPartial } from "app2/api";
import { isNewId } from 'app2/components';
import { courseKindBehavior } from 'app2/views/shared-public';

type CoursePermissionsMap = { [prop in keyof DeepPartial<Course>]: {disabled:boolean, required: boolean }};

export function getPermissions(course:DeepPartial<Course>):CoursePermissionsMap {
  const enabled = getEnabled(course);
  const required = getRequired(course);
  const both = {} as CoursePermissionsMap;

  for (const propName in enabled) {
    const prop = propName as keyof Course;
    both[prop] = {disabled: !enabled[prop], required: required[prop]}
  }

  return both;
}

type CourseBoolMap = { [index:string]: (undefined | false | true) };
type CourseMultiBoolMap = { [index:string]: (undefined | false | true)[]; };

// prettier-ignore
/* prettier-ignore */
function getEnabled(course:DeepPartial<Course>):CourseBoolMap {
  type statusColMappingType = {[key in CourseStatus]? : number};
  const courseStatusColMapping: statusColMappingType = {
    'DRAFT': 2,
    'REQUEST': 3
  }

  const x = true;
  const cd = courseKindBehavior[course.kind]?.fields?.courseDays !== false && courseKindBehavior[course.kind]?.sessionType != 'time-slots';
  const tmc = courseKindBehavior[course.kind]?.fields?.teamMaxCapacity !== false;
  const t = courseKindBehavior[course.kind]?.fields?.courseTimes !== false;

  const all:CourseMultiBoolMap = {
  // D = draft
  // R = requested
  // A+ = Approved and beyond

  //                                                           Vendor   | Organizer
  //                                                      NonP | PT  |  D  |  R  |  A+
    name:                                                [  x  ,  x  ,  x  ,  x  ,  x  ],
    kind:                                                [  x  ,  x  ,  x  ,  x  ,  x  ],
    courseDays:                                          [  cd ,  cd ,  cd ,  cd ,  cd ],
    'courseDays.0.start':                                [  t  ,  t  ,  t  ,  t  ,  t  ],
    'courseDays.0.finish':                               [  t  ,  t  ,  t  ,  t  ,  t  ],
    startTime:                                           [  t  ,  t  ,  t  ,  t  ,  t  ],
    endTime:                                             [  t  ,  t  ,  t  ,  t  ,  t  ],
    startDate:                                           [  x  ,  x  ,  x  ,  x  ,  x  ],
    endDate:                                             [  x  ,  x  ,  x  ,  x  ,  x  ],
    noEnrichmentDays:                                    [  x  ,  x  ,  x  ,  x  ,  x  ],
    room:                                                [  x  ,  x  ,  x  ,  x  ,  x  ],
    grades:                                              [  x  ,  x  ,  x  ,  x  ,  x  ],
    ageMin:                                              [  x  ,  x  ,  x  ,  x  ,  x  ],
    ageMax:                                              [  x  ,  x  ,  x  ,  x  ,  x  ],
    description:                                         [  x  ,  x  ,     ,     ,  x  ],
    courseTags:                                          [  x  ,  x  ,     ,     ,  x  ],
    supplies:                                            [  x  ,  x  ,     ,     ,  x  ],
    courseImage:                                         [  x  ,  x  ,     ,     ,  x  ],
    courseCardImage:                                     [  x  ,  x  ,     ,     ,  x  ],
    minCapacity:                                         [  x  ,  x  ,  x  ,  x  ,  x  ],
    maxCapacity:                                         [  x  ,  x  ,  x  ,  x  ,  x  ],
    teamMaxCapacity:                                     [ tmc , tmc , tmc , tmc , tmc ],
    courseFramework:                                     [  x  ,  x  ,     ,     ,     ],
    teacher:                                             [  x  ,  x  ,     ,     ,     ],
    enrollmentOpens:                                     [  x  ,     ,  x  ,  x  ,  x  ],
    'enrollmentOpens.date':                              [  x  ,     ,  x  ,  x  ,  x  ],
    'enrollmentOpens.time':                              [  x  ,     ,  x  ,  x  ,  x  ],
    enrollmentCloses:                                    [  x  ,     ,  x  ,  x  ,  x  ],
    'enrollmentCloses.date':                             [  x  ,     ,  x  ,  x  ,  x  ],
    'enrollmentCloses.time':                             [  x  ,     ,  x  ,  x  ,  x  ],
    feeRate:                                             [     ,     ,  x  ,  x  ,  x  ],
    feeType:                                             [     ,     ,  x  ,  x  ,  x  ],
    vendor:                                              [     ,     ,  x  ,     ,     ],
    site:                                                [     ,     ,  x  ,     ,     ],
    'rates.season.rate':                                 [  x  ,  x  ,  x  ,  x  ,  x  ],
    'rates.season.homeroomFeeRoundingIncrementCents':    [  x  ,  x  ,  x  ,  x  ,  x  ],
    'rates.season.materialsRate':                        [  x  ,  x  ,  x  ,  x  ,  x  ],
    'rates.season.depositAmount':                        [  x  ,  x  ,  x  ,  x  ,  x  ],
    'rates.season.installmentDates':                     [  x  ,  x  ,  x  ,  x  ,  x  ],
    'rates.seasons':                                     [  x  ,  x  ,  x  ,  x  ,  x  ],
    'rates.seasons.days':                                [  x  ,  x  ,  x  ,  x  ,  x  ],
    'rates.seasons.rate':                                [  x  ,  x  ,  x  ,  x  ,  x  ],
    'rates.seasons.homeroomFeeRoundingIncrementCents':   [  x  ,  x  ,  x  ,  x  ,  x  ],
    'rates.dropIn.rate':                                 [  x  ,  x  ,  x  ,  x  ,  x  ],
    'rates.recurring':                                   [  x  ,  x  ,  x  ,  x  ,  x  ],
    'rates.recurring.days':                              [  x  ,  x  ,  x  ,  x  ,  x  ],
    'rates.recurring.rate':                              [  x  ,  x  ,  x  ,  x  ,  x  ],
    'rates.recurring.homeRoomFeeRoundingIncrementCents': [  x  ,  x  ,  x  ,  x  ,  x  ],
    'rates.recurring.startDateDisabled':                 [  x  ,  x  ,  x  ,  x  ,  x  ],
    'rates.recurring.unit':                              [  x  ,  x  ,  x  ,  x  ,  x  ],
    'rates.usage.rate':                                  [  x  ,  x  ,  x  ,  x  ,  x  ],
    'rates.usage.homeroomFeeRoundingIncrementCents':     [  x  ,  x  ,  x  ,  x  ,  x  ],
    'rates.usage.unit':                                  [  x  ,  x  ,  x  ,  x  ,  x  ],
    'rates.usage.roundingIncrement':                     [  x  ,  x  ,  x  ,  x  ,  x  ],
    'rates.usage.gracePeriod':                           [  x  ,  x  ,  x  ,  x  ,  x  ],
  }

  let enabled:CourseBoolMap = {};

  const userSiteRole = course.site?.userSiteRole;

  if (userSiteRole) {
    const organizerCol = courseStatusColMapping[course.status] || 4;

    mergeFlags(enabled, all, organizerCol);
  }

  const userCompanyRole = course.company?.userCompanyRole || course.vendor?.company?.userCompanyRole;

  if (userCompanyRole) {
    mergeFlags(enabled, all, course.site?.partner ? 1 : 0);

    // hack to allow vendors to edit their site when creating a new non-partner
    // site which is not represented in the cols above

    if (!course.site?.partner && isNewId(course.id)) {
      enabled.site = true;
    }
  }

  return enabled;
}

// prettier-ignore
/* prettier-ignore */
function getRequired(course:DeepPartial<Course>):CourseBoolMap {
  const rates = CourseUtils.getRateOrPriceState(course?.rates).required;

  const x = true;
  const cd = courseKindBehavior[course.kind]?.fields?.courseDays !== false && courseKindBehavior[course.kind]?.sessionType != 'time-slots';
  const tmc = courseKindBehavior[course.kind]?.fields?.teamMaxCapacity !== false;
  const t = courseKindBehavior[course.kind]?.fields?.courseTimes !== false;

  const all:CourseMultiBoolMap = {
  // D = Draft
  // R = Requested
  // A = Awaiting approval
  // L = Listed (all other statuses)
  //                                   D R |  A   | L  
    name:                            [     ,  x  ,  x  ],
    type:                            [     ,     ,     ],
    description:                     [     ,     ,     ],
    courseTags:                      [     ,     ,     ],
    supplies:                        [     ,     ,     ],
    courseImage:                     [     ,     ,     ],
    courseCardImage:                 [     ,     ,     ],
    vendor:                          [  x  ,  x  ,  x  ],
    site:                            [  x  ,  x  ,  x  ],
    grades:                          [     ,     ,     ],
    ageMin:                          [     ,     ,     ],
    ageMax:                          [     ,     ,     ],
    room:                            [     ,     ,     ],
    courseFramework:                 [     ,  x  ,  x  ],
    teacher:                         [     ,     ,     ],
  //hideable:                        [     ,     ,     ],
    courseDays:                      [  cd ,  cd ,  cd ],
    'courseDays.0.start':            [  t  ,  t  ,  t  ],
    'courseDays.0.finish':           [  t  ,  t  ,  t  ],
    startDate:                       [  x  ,  x  ,  x  ],
    endDate:                         [  x  ,  x  ,  x  ],
    noEnrichmentDays:                [     ,     ,     ],
    enrollmentOpens:                 [     ,     ,  x  ],
    enrollmentCloses:                [     ,     ,  x  ],
    minCapacity:                     [     ,  x  ,  x  ],
    maxCapacity:                     [     ,  x  ,  x  ],
    teamMaxCapacity:                 [ tmc , tmc , tmc ],
    feeRate:                         [     ,     ,     ],
    feeType:                         [     ,     ,     ],
  //updateSchoolFee:                 [     ,     ,     ],

  'rates.season.materialsRate':      [     ,     ,     ],
    'rates.season.rate':             [     ,  rates.season    ,  rates.season  ],
    'rates.season.depositAmount':    [     ,     ,     ],
    'rates.season.installmentDates': [     ,     ,     ],
    'rates.season.homeroomFeeRoundingIncrementCents': [     ,     ,     ],
    'rates.seasons':                 [     ,  rates.seasons  ,  rates.seasons  ],
    'rates.seasons.days':            [     ,  rates.seasons  ,  rates.seasons  ],
    'rates.seasons.rate':            [     ,  rates.seasons  ,  rates.seasons  ],
    'rates.seasons.homeroomFeeRoundingIncrementCents': [     ,     ,     ],
    'rates.dropIn.rate':             [     ,  rates.dropIn    ,  rates.dropIn  ],
    'rates.dropIn.homeroomFeeRoundingIncrementCents': [     ,     ,     ],
    'rates.recurring':               [     ,  rates.recurring ,  rates.recurring  ],
    'rates.recurring.days':          [     ,  rates.recurring ,  rates.recurring  ],
    'rates.recurring.rate':          [     ,  rates.recurring ,  rates.recurring  ],
    'rates.recurring.homeroomFeeRoundingIncrementCents': [     ,     ,      ],
    'rates.recurring.startDateDisabled': [     ,  rates.recurring ,  rates.recurring  ],
    'rates.recurring.unit':          [     ,  rates.recurring ,  rates.recurring  ],
    'rates.usage.rate':              [     ,  rates.usage     ,  rates.usage  ],
    'rates.usage.homeroomFeeRoundingIncrementCents': [     ,     ,     ],
    'rates.usage.unit':              [     ,  rates.usage     ,  rates.usage  ],
    'rates.usage.roundingIncrement': [     ,  rates.usage     ,  rates.usage  ],
    'rates.usage.gracePeriod':       [     ,  rates.usage     ,  rates.usage  ],
}

  let required:CourseBoolMap = {};
  const col = {
    undefined: 0,
    null: 0,
    '': 0,
    [CourseStatus.Draft]: 0,
    [CourseStatus.Request]: 0,
    [CourseStatus.AwaitingApproval]: 1,
    [CourseStatus.Active]: 2,
    [CourseStatus.Cancelled]: 2,
    [CourseStatus.Completed]: 2,
    [CourseStatus.Deleted]: 2,
    [CourseStatus.Enrolling]: 2,
    [CourseStatus.Upcoming]: 2
  }[course.status] 

  mergeFlags(required, all, col);

  return required;
}

function mergeFlags(toMerge:CourseBoolMap, existing:CourseMultiBoolMap, col:number) {
  for (const name in existing) {
    toMerge[name as keyof CourseBoolMap] = toMerge[name as keyof CourseBoolMap] || existing[name as keyof CourseBoolMap][col] || false;
  }
}
