import { ColPref, ExpressionNode } from 'app2/components';

import { GeneratedQueryArgs } from './HrDataTable';
import { useSortFilterV1 } from './useSortFilterV1';
import { useSortFilterV2 } from './useSortFilterV2';

export function useVersionedSortFilter<T = any, P extends keyof T = any>(version:'v1' | 'v2', colDefs:ColPref<T, P>[], queryOptions?: GeneratedQueryArgs<T, P>, advancedFilter?:ExpressionNode) {
  return version == 'v1'
    ? useSortFilterV1(colDefs, queryOptions)
    : useSortFilterV2(colDefs, queryOptions, advancedFilter)
}

