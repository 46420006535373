import { cloneDeep } from 'lodash-es';

import { Command } from "../../undo";

import { DataTable, DataCellPositionIds } from "..";
import { ObservableCollectionCommand, CollectionEventType, CollectionEvent } from "../collection";

export class AppendRowCommand<T> implements Command, ObservableCollectionCommand<T> {
  table:DataTable;
  ids:DataCellPositionIds;
  item:T;

  constructor(table:DataTable, item?:T) {
    this.table = table;
    this.item = item;
  }

  get isCollectionCommand() {
    return true;
  }

  get collectionEvents():CollectionEvent<T>[] {
    const position = this.table.idsToPos(this.ids).row;

    return [{
      collection: this.table.data,
      type: CollectionEventType.create,
      position,
      id: this.ids.rowId,
      item: position != -1 ? this.table.data.getItem(position) : this.item
    }]
  }

  focus():void {
    const table = this.table;
    table.focus();

    // subtract one from the row since undo is removing the added row
    table.setSelection(table.selection.clone().moveTo(table.idsToPos(this.ids).clone().offset(0, -1)), true);
  }

  do():void {
    const table = this.table;

    if (!this.item) {
      this.item = cloneDeep(table.props.defaultValues || {}) as T;
      delete (this.item as any).id;
    }

    table.editableData.insert(table.data.length, this.item);

    const selection = table.selection.clone().moveDown(true);
    table.forceUpdate(() => table.setSelection(selection, true));

    this.ids = table.posToIds(selection.topLeft.clone());
  }

  undo():void {
    const pos = this.table.idsToPos(this.ids);
    this.item = this.table.editableData.remove(pos.row);
  }

  redo():void {
    this.do();
  }
}
