import * as React from 'react'

import { compact, CfQuestion, CfKind, CfOwnerKind } from 'app2/api';
import { DataTableColumn, BooleanLabelField, arrayToString } from 'app2/components';
import { answerId, FileLinks } from 'app2/views/shared';

import { CfOwnerProps, useCfQuestionsQuery } from './useCfQuestionsQuery'

export function useCfQuestionCols<T>(props:CfOwnerProps):DataTableColumn<T>[];
export function useCfQuestionCols<T>(questions:CfQuestion[], ownerType?:CfOwnerKind):DataTableColumn<T>[];
export function useCfQuestionCols<T>(questionsOrProps:CfOwnerProps | CfQuestion[], maybeOwnerType:CfOwnerKind = CfOwnerKind.Course) {
  const {questions, ownerType} = normalizeCfInputs(questionsOrProps, maybeOwnerType)

  return React.useMemo(() => {
    return questionsToCols(questions, ownerType);
  }, [questions, ownerType]);
}

export function normalizeCfInputs<T>(questionsOrProps:CfOwnerProps | CfQuestion[], ownerType:CfOwnerKind = CfOwnerKind.Course) {
  let questions:CfQuestion[];

  if (questionsOrProps && !Array.isArray(questionsOrProps)) {
    const result = useCfQuestionsQuery(questionsOrProps || {});
    questions = result?.questions;
    ownerType = result?.type;
  }
  else {
    questions = questionsOrProps as CfQuestion[];
    ownerType = ownerType || CfOwnerKind.Course;
  }

  return {questions, ownerType};
}

export function questionsToCols<T>(questions:CfQuestion[], ownerType:CfOwnerKind = CfOwnerKind.Course) {
  const answerName = ownerType == CfOwnerKind.Course ? 'cfAnswers.' : 'cfSeasonAnswers.';
  const reportAnswerName = ownerType == CfOwnerKind.Course ? 'cfFormattedAnswers.' : 'cfFormattedSeasonAnswers.';
  const cfCols = questions?.map(q => {
    return compact({
      name: answerName + answerId(q) as keyof T, 
      reportId: reportAnswerName + answerId(q),
      label: q.title, 
      width: 200,
      component: q.kind == CfKind.Upload ? FileLinks : q.kind == CfKind.Confirmation ? BooleanLabelField : undefined,
      format: q.kind == CfKind.MultipleChoice ? arrayToString : undefined,
      readOnly: true
    })
  }) || [];

  return cfCols;
}
