import { ErrorWithPath, ErrorPathSegmentType, errorHasPath, errorToString } from "../../error";
import { FieldInfo, FieldModel, mergeFieldComponentProps, getValues } from "../../form";

import { ObservableCollectionProxy, CollectionEventType, ObservableCollection } from "../collection";
import { DataTable } from "../DataTable";

import { FormCollection } from "./FormCollection";

interface RowError {
  row:string[];
  fields:{[field:string | symbol | number]:string[]};
}

type RowErrors = {[row:string]:RowError};

export class ReadonlyFormCollection<T> extends ObservableCollectionProxy<T> implements FormCollection<T> {
  table:DataTable;
  recordErrors:RowErrors;

  constructor(table:DataTable, source?:ObservableCollection<any>) {
    super(source);

    this.table = table;
    this.recordErrors = {};
  }

  release():void {
  }

  getField?<P extends keyof T>(parents:(string | number)[], name:P):FieldModel<T, P>;
  getField?<P extends keyof T>(row:number, name:P):FieldModel<T, P>;
  getField?<P extends keyof T>(parentsOrPosition:(string | number)[] | number, name:P):FieldModel<T, P> {
    const col = this.table.getColumnByName(name as string);
    const field = mergeFieldComponentProps(col, false) as FieldModel<T, P>;

    return field;
  }

  getValue(parentsOrPosition:(string | number)[] | number, fieldName: string, defaultValue?: any) {
    const row = Array.isArray(parentsOrPosition) ? parentsOrPosition[0] as number : parentsOrPosition;
    const result = this.proxied.getValue(row, fieldName as any);

    return result === undefined ? defaultValue : result;
  }

  getDirty(position:number):boolean {
    return false;
  }

  getInfo(parents:(string | number)[], fieldName: any, defaultValue?: any):FieldInfo<T> {
    const row = parents[0] as number;
    const value = this.proxied.getItem(row);
    const parts = fieldName.split('.');
    const values = getValues(value, parts);

    const info = {
      form: this,
      name: fieldName,
      value: values[values.length - 1],
      values,
      record: values[values.length - 2],
      parents: [] as string[],
      touched: false,
      errors: this.recordErrors?.[row]?.fields?.[fieldName] || [],
      required: false,
      disabled: false,
      readOnly: true
    };
  
    return info as FieldInfo<T>;
  }

  getRecordErrors(row:number):string[] {
    return this.recordErrors?.[row]?.row || [];
  }

  handleErrors(errors:ErrorWithPath[], clearPrevious?:boolean):ErrorWithPath[] {
    if (clearPrevious || clearPrevious === undefined) {
      this.clearErrors();
    }
    
    const unhandled = [];

    for (let error of errors) {
      if (!errorHasPath(error) || !this.applyErrorToRow(error)) {
        unhandled.push(error);
      }
    }

    return unhandled;
  }

  applyErrorToRow(error:ErrorWithPath) {
    const row = this.rowFromErrorPath(error);

    if (row == -1 || row >= this.proxied.length) {
      return false;
    }

    if (!this.recordErrors[row]) {
      this.recordErrors[row] = {row:[], fields:{}};
    }

    if (this.applyErrorToField(row, error)) {
      return true;
    }

    // remove the row id from the path since we found the row
    this.recordErrors[row].row.push(errorToString({...error, path:error.path.slice(1)}));
    this.onFormError(row);

    return true;
  }

  rowFromErrorPath(error:ErrorWithPath) {
    const part = error.path[0];
    const row = part.type == ErrorPathSegmentType.property || part.type == ErrorPathSegmentType.index
      ? Number(part.value) 
      : this.getIndex(part.value);

    return isNaN(row) ? -1 : row;
  }

  applyErrorToField(row:number, error:ErrorWithPath) {
    const path = error.path.slice(1);

    while (path.length) {
      const field = this.table.getColumnByName(path.map(p => p.value).join('.'));

      if (field && !field.hidden) {
        if (!this.recordErrors[row].fields[field.name]) {
          this.recordErrors[row].fields[field.name] = [];
        }

        const fieldErrors = this.recordErrors[row].fields[field.name];

        // remove the path since we found the field
        fieldErrors.push(errorToString({...error, path:[]}));
        this.onFormError(row);

        return true;
      }

      path.pop();
    }

    return false;
  }

  clearErrors() {
    this.recordErrors = {};
    this.table.onDataUpdate({collection: this, type: CollectionEventType.reset});
  }

  onFormError(row:number) {
    const item = this.proxied.getItem(row) as any;
    this.table.onDataUpdate({collection: this, type: CollectionEventType.update, position: row, item, id: item.id});
  }
}
