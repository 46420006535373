import * as React from 'react'
import { UseQueryResponse, UseQueryState } from 'urql';
import { get } from 'lodash-es';

import { defaultFieldProps, Dropdown, DropdownProps, Option, OptionText, useFormInfo } from 'app2/components';

import { UseQueryArgs } from './urql';

// SearchDropdown is Dropdown that is tied to a
// graphql query...only use this if your query function and
// results fit easily with the required types, else just
// use Dropdown directly which has a search callback

// your query must accept "term" as a variable

// note that it expects the "value" property (if supplied)
// to be a an object with id and name properties (not just an id)
// it needs the name property so it can do a search
// on the initial name

interface QueryResultItem {
  id:string;
  name?:string;
}


type QueryResult<I> = {[key: string]: Array<I>}


export type SearchQueryArgs<V = any, D = any> = Omit<UseQueryArgs<V, D>, 'query'>;
export type SearchQueryHook<V = any, D = any> = (args: SearchQueryArgs<V, D>) => UseQueryResponse<D, V>;

export interface SearchDropdownProps<T extends QueryResultItem = QueryResultItem> extends Omit<DropdownProps, 'value' | 'options'> {
  value?:T;
  default?:T;

  query?:SearchQueryHook<any, QueryResult<T>>;
  queryVars?:any;
  variables?:(term:string) => any;
  resultProp?:string;
  renderItem?:(item:T) => string;
  // for generating the default options from the value 
  // so that we don't call the initial search...wait 
  // until the user hits the dropdown
  renderOptions?:(item:T) => Option<T>[];
}

export function SearchDropdown<T extends QueryResultItem = QueryResultItem>(props:SearchDropdownProps<T>) {
  const {query, queryVars, variables, resultProp, renderItem, renderOptions, ...remaining} = props;

  const [term, setSearchTerm] = React.useState(props.value?.name);
  const [opened, setOpened] = React.useState(false);

  const info = useFormInfo();
  const value = props.value || props.default;
  const editing = (info?.editing || info === undefined) && !props.readOnly;
  const readOnlyOptions = React.useMemo(() => (value !== undefined ? [{label: props.renderItem(value), value: value}]: []), [value]);

  const options = getItems();

  function render() {
    return editing
      ? <Dropdown {...remaining} value={value} options={options} onSearchChange={onSearchChange} onDropdown={onDropdown} />
      : <OptionText value={value} options={readOnlyOptions} />
  }

  function getItems() {
    // if term got initialized to undefined, use an updated value if
    // we get one, else we use term.  do this because sometimes the initial
    // value is not yet defined until subsequent renders
    const termToSearch = term !== undefined ? term : value?.name;
    const [response] = query({variables:{term: (variables ? variables(termToSearch || '') : termToSearch) || '', ...queryVars}, hideLoader: true, autoPause: false, pause: !opened, debounce: {delay:350}});
    const data = getData(response);

    return React.useMemo(() => {
      return !opened ? renderOptions(value) : data?.map(result => {return {label: renderItem(result), value: result}});
    }, [response, opened, value]);
  }

  function getData(response: UseQueryState<QueryResult<T>, any>) {
    if (!response.data) {
      return;
    }

    if (!resultProp) {
      return Object.values(response.data)?.[0] as T[];
    }

    return get(response.data, resultProp) as T[];
  }

  function onSearchChange(newTerm:string) {
    setSearchTerm(newTerm);
  }

  function onDropdown(open:boolean) {
    if (opened) {
      return;
    }

    setOpened(true);
  }

  return render();
}

SearchDropdown.defaultProps = {
  renderItem: itemToLabel,
  renderOptions: itemToOptions
}

SearchDropdown.fieldProps = {
  ...defaultFieldProps,
  readOnlyProperty: 'readOnly'
}

function itemToLabel<T extends QueryResultItem = QueryResultItem>(item:T) {
  return item.name;
}

function itemToOptions<T extends QueryResultItem = QueryResultItem>(item:T) {
  return [{label: item?.name, value: item}]
}
