import * as React from 'react';
import { capitalize } from 'lodash-es';

import { Student } from 'app2/api';
import { Form, Section, InputField, FormModel, useForm, validateEmail } from 'app2/components';
import { CartCourse, courseTerms, EnrollmentForm, getEnrollmentConfig, EnrollmentConfigurationForm } from 'app2/views/shared-public';

import { StudentDropdown } from '../../../../student';
import { errorPathTransform } from '../../../../error';
import { CourseSelections } from '../../../generated';
import { DiscountComboAndBreakdown, EnrollmentDiscountForm } from '../price-config';

import { EnrollmentChangeWarnings } from './EnrollmentChangeWarnings';
import { TimeSlotPicker } from './TimeSlotPicker';
import { addStudent, AddStudentMutationVariables } from './gql';

interface FormValues extends EnrollmentForm, EnrollmentDiscountForm {
  name: string;
  email: string;
  student?: Student;
  childCourseVariant:CartCourse & {site:{id:string}};
}

type AddStudentFormModel = FormModel<FormValues>;

interface Props {
  course: CourseSelections;
  type: 'invite' | 'add';
}

export function AddStudentForm(props: Props) {
  const kind = props.course.kind;
  const form = useForm<FormValues>({ format: null, rate: 0 }, [props.course]);
  const config = getEnrollmentConfig(form);

  // see src/app2/views/shared/course-page/Activity.tsx containerVars
  const queryVars = props.course?.site?.userSiteRole 
    ? {site: props.course.site.id} 
    : props.course?.vendor?.company?.userCompanyRole
      ? {company: props.course.vendor?.company?.id} 
      : {}

  function render() {
    return (
      <Form width="100%" form={form} onOk={handleSubmit} editing onNavigation="nothing">
        <TimeSlotPicker course={props.course} />
        {props.type == 'invite' && <Section label={`${capitalize(courseTerms(kind).participant)} name`} name="name" component={InputField} required />}
        {props.type == 'invite' && <Section label={`${capitalize(courseTerms(kind).family)} email`} name="email" component={InputField} required validators={validateEmail} />}
        {props.type == 'add' && <Section label="Student name" name="student" {...queryVars} component={StudentDropdown} placeholder={`Select ${courseTerms(kind).participant}`} required />}
        <EnrollmentConfigurationForm course={getCourse()} form={form} recurring={{startDate: true}} />
        <DiscountComboAndBreakdown parentCourse={props.course} course={getCourse()} config={config} invite={props.type == 'invite'} />
        <EnrollmentChangeWarnings course={getCourse()} config={config} type={props.type} />
      </Form>
    );
  }

  function getCourse() {
    return form.values.childCourseVariant || props.course;
  }

  async function handleSubmit(form: AddStudentFormModel) {
    const values = form.values;
    const variables: AddStudentMutationVariables = {
      id: getCourse().id,
      parentEmail: props.type == 'invite' ? values.email : undefined,
      studentName: props.type == 'invite' ? values.name : undefined,
      studentId: props.type == 'add' ? values.student?.id : undefined,
      kind: config.kind,
      recurringPriceConfig: config.recurring,
      dropInPriceConfig: config.dropIn,
      configurableSeason: config.configurableSeason,
    };

    if (values.format) {
      variables.discount = { format: values.format, rate: values.rate };
    } else if (values.discount) {
      variables.discountId = values.discount;
    }

    const [success] = await addStudent({
      variables,
      successMsg: `${props.type == 'invite' ? 'Invitation sent' : 'Student added'}`,
      error: {
        handler: form,
        transform: [errorPathTransform('discount.format', 'format'), errorPathTransform('discount.rate', 'rate'), errorPathTransform('recurringPriceConfig', 'recurring')]
      }
    });

    return success;
  }

  return render();
}
