import { get, orderBy } from 'lodash-es';

import { BoxProps } from '../../Box';
import { FieldProps } from '../../form';
import { Option, OptionValue, getOptionStringValue } from '../../Option';
import { mergeFieldComponentProps } from '../../form';

import { DataTable} from '../DataTable';

export type GetFilterOptions<T, P extends keyof T = keyof T> = (table:DataTable<T>, col:DataTableColumn<T, P>) => Promise<Option[]> | Option[];
export type OnSortFilter<T, P extends keyof T = keyof T> = (table:DataTable<T>, col:DataTableColumn<T, P>, sort:DataTableColumnSort, filter:OptionValue[]) => void;

export interface DataTableColumn<T = any, P extends keyof T = keyof T> extends FieldProps<T, P, any> {
  // width in pixels.  if you specify -1, it will use the entire scrollable area width (less scrollbar)
  width?:number;
  header?:BoxProps;
  hidden?:boolean;

  sortable?:boolean;
  filterable?:boolean;
  allowAdditionalFilterValues?:boolean;

  sort?:DataTableColumnSort;
  filter?:OptionValue[];
  // gets the filter options for a column for the filter menu
  // if not implemented, the default behavior is to get the values from the 
  // table (if filterable or table.filterable is true)
  getFilterOptions?:GetFilterOptions<T, P>;
  filterType?:DataTableFilterType;

  // for when the table does built sorting vs. when the table uses sort
  // callbacks you can customize the sort behavior by mapping to a standard
  // sortable value
  sortValue?:(a:T[P]) => number | string;

  // called when either the sort or filter changes
  // its expected that you will re-render the table with a new
  // data property *or* you can call table.set data though that
  // will get reset if any of the datatable properties change
  // also it's expected sort/filter only works in non-edit mode currently
  onSortFilter?:OnSortFilter<T, P>;

  // sometimes there are multiple columns with the same field name
  // because they refer to the same piece of data, id can
  // be used to disambiguate cols if you are searching the collection
  // of columns by a string.  the core table uses this when
  // trying to find a column by name.
  id?:string;

  // this is yet another alias, that is used to identify the column
  // for when generating reports and the columns have slightly different
  // names than the field name (used to allow the server to do alternative formatting)
  reportId?:string;

  // this is readonly and used internally to track where page 
  // breaks occur if the table is paginated
  break?:boolean;

  // same as the field infoTip, but for the column
  infoTip?:React.ReactNode;
}

// theres many places where the option name, or the name typing as string | symbol | number causes issue
// so this type is useful for casting in dealing with those places
export type DataTableColumnWithStringName< T= any, P extends Extract<keyof T, string> = Extract<keyof T, string>> = Omit<DataTableColumn<T, P>, 'name'> & {name: P};

export type DataTableFilterType = 'list' | 'date-range';

export enum DataTableColumnSort {
  none,
  ascending,
  descending
}

export function defaultGetFilterOptions<T>(table:DataTable<T>, col:DataTableColumn<T>) {
  const options:Option[] = [];
  const values = new Set();
  const field = mergeFieldComponentProps(col, false);
  const data = table.unfilteredData;

  for (let row = 0; row < data.length; ++row) {
    const info = data.getInfo([row], col.name);
    const stringValue = getOptionStringValue(info.value);

    if (!values.has(stringValue)) {
      values.add(stringValue);

      const labelFn = field.format || field.copy || (value => value === null || value === undefined ? '' : value.toString())
      options.push({value: info.value, label: labelFn(info.value, field, info)})
    }
  }

  return options;
}

export function defaultSortFilter<T>(table:DataTable<T>, col:DataTableColumn<T>, sort:DataTableColumnSort, filter:OptionValue[]) {
  const filtered = defaultFilter(table, col);
  table.data = defaultSort(col, filtered);
}

function defaultFilter<T>(table:DataTable<T>, col:DataTableColumn<T>) {
  const filtered:any = []
  const data = table.unfilteredData;
  const filteredSet = col.filter ? new Set(col.filter.map(val => getOptionStringValue(val))) : null;

  for (let row = 0; row < data.length; ++row) {
    const value = data.getValue([row], col.name);

    if (!filteredSet || filteredSet.has(getOptionStringValue(value))) {
      filtered.push(data.getItem(row));
    }
  }

  return filtered;
}

function defaultSort<T>(col:DataTableColumn<T>, data:any[]) {
  if (!col.sort) {
    return data;
  }

  return orderBy(data, col.sortValue || col.name, col.sort == DataTableColumnSort.ascending ? 'asc' : 'desc');
}

export function getFieldPropsFromCol<T>(col:DataTableColumn<T>) {
  const {width, header, hidden, sortable, filterable, allowAdditionalFilterValues, sort, filter, filterType, filterName, getFilterOptions, sortValue, onSortFilter, break, infoTip, reportId, ...fieldProps} = col;

  return fieldProps;
}

export function colId<T = any, P extends keyof T = any>(col:string | P | DataTableColumn<T, P>):string {
  const colObj = col as unknown as DataTableColumn;
  return !col ? '' : typeof col == 'string' ? col : colObj.id || colObj.reportId || colObj.name as string;
}

// favors name over id because name is what is used to get to the data
export function colName<T = any, P extends keyof T = any>(col:string | P | DataTableColumn<T, P>):string {
  const colObj = col as unknown as DataTableColumn;
  return !col ? '' : typeof col == 'string' ? col : colObj.name  as string || colObj.id || colObj.reportId;
}

// favors reportId then name over id because name is what is used to get to the data
export function colReportId<T = any, P extends keyof T = any>(col:string | P | DataTableColumn<T, P>):string {
  const colObj = col as unknown as DataTableColumn;
  return !col ? '' : typeof col == 'string' ? col : colObj.reportId  as string || colObj.name  as string || colObj.id;
}

// returns the function to get text for a column
export function getColFormatFunction<T>(col:DataTableColumn<T>) {
  const fieldProps = mergeFieldComponentProps(col, false);
  const fn = fieldProps.copy || fieldProps.format || ((value:any) => value);
  const getText = (record:T):string => fn(get(record, col.name), fieldProps);

  return {col, getText, fieldProps};
}

export function getColText<T>(col:DataTableColumn<T>, record:T) {
  const fn = getColFormatFunction(col);

  return fn.getText(record);
}

// returns a function to get values for multiple columns at once (with a separator)
export function getColsFormatFunction<T = any>(cols:DataTableColumn<T>[], separator:string = ' ') {
  const fns = cols.map(getColFormatFunction)

  return function (record:T) {
    return fns.map(fn => fn.getText(record)).filter(label => !!label).join(separator);
  }
}

export function compareRows<T>(a:T, b:T, cols:DataTableColumn<T>[], defaultToAscend:boolean = true) {
  for (let col of cols) {
    const sort = col.sort || (defaultToAscend ? DataTableColumnSort.ascending : DataTableColumnSort.none);

    if (sort == DataTableColumnSort.none) {
      continue;
    }

    let valA = get(a, col.name);
    let valB = get(b, col.name);

    valA = col.sortValue ? col.sortValue(valA) : valA;
    valB = col.sortValue ? col.sortValue(valB) : valB;

    if ((valA ?? undefined) === (valB ?? undefined)) {
      continue;
    }

    const compare = typeof valA == 'string'
      ? valA.localeCompare(valB)
      : valA - valB

    if (compare !== 0) {
      return sort == DataTableColumnSort.ascending ? compare : -compare;
    }
  }
  
  return 0;
}
