import * as React from 'react';

import { useParams } from 'react-router';

import { Modal, MenuItem, OptionsMenu, Tabs, VBox } from 'app2/components';
import { CfQuestionsEditor, Module, removePath } from 'app2/views/shared';

import { SeasonRouteParams } from '../organizerRoutes';
import { Discounts, SeasonDropdown, useCurrentSite, useOrganizerSeasonQuery } from '../shared';

import { SeasonCourses } from './SeasonCourses';
import { SeasonForm } from './SeasonForm';
import { organizerDeleteSeason } from './gql';

export function CurrentSeason() {
  const { season: seasonId } = useParams<SeasonRouteParams>();
  const { site } = useCurrentSite();
  const [seasonResult] = useOrganizerSeasonQuery({ variables: { seasonId } });
  const season = seasonResult.data?.season;

  function render() {
    return <Module title="Scheduled seasons" actions={<OptionsMenu><MenuItem icon="Trash2" iconSize='small' onClick={handleDeleteClick} autoLoader={false}>Delete season</MenuItem></OptionsMenu>}>
      <SeasonDropdown />
      <Tabs tabs={[
        {label: 'Details', content:<VBox gap='$30'><SeasonForm title="Season details" site={site} season={season} /><SeasonCourses /></VBox>},
        {label: 'Registration questions', content:<CfQuestionsEditor owner={season} courseKinds={season?.courseKindGroups} />},
        {label: 'Discounts', content:<Discounts ownerId={seasonId} ownerType="season" />}
      ]}
      />
    </Module>
  }

  async function handleDeleteClick() {
    const warningResult = await Modal.warning({
      title: "This action can't be undone",
      content: 'Are you sure you want to delete this season?',
      ok: 'Continue'
    });
    if (!warningResult.action) {
      return;
    }

    const [success] = await organizerDeleteSeason({ variables: { siteId: site.id, ids: season.id }, error: {transform: removePath}, successMsg: 'Season deleted.' });

    return success;
  }

  return render();
}
