import * as React from 'react';

import { Button, ButtonProps, focused, disabled } from './Button';

export function ButtonStripButton(props:ButtonProps) {
  return <Button kind='secondary' minHeight='38px' fontWeight='600' css={css} overflow='hidden' zIndex={props.selected ? 1 : undefined} 
    borderColor={props.selected ? 'primary' : 'border'} minWidth='fit-content' {...props} />
}

const css = {
  ':not(:first-child)': {
    borderTopLeftRadius: 'unset',
    borderBottomLeftRadius: 'unset',
  },
  ':not(:last-child)': {
    borderTopRightRadius: 'unset',
    borderBottomRightRadius: 'unset',
  },
  [focused]: {
    outlineOffset: '-2px!important',
  },
  [disabled]: {
    borderColor: 'border'
  }
} as React.CSSProperties
