import * as React from 'react';
import moment from 'moment';

import { DateField, DataTableFilterType, DataTable, DataTableColumn, DataTableColumnSort, EmailField, Option, TimeField } from 'app2/components';

import { TimeTrackingProps, getOwner } from '../TimeTrackingProps';

import { TimeEntriesSelections, timeEntriesFilterOptions } from './generated';

export function useTimeTrackingCols(props:TimeTrackingProps) {
  return React.useMemo(() => getCols(props), [props.entity]);
}

function getCols(props:TimeTrackingProps) {
  const common = {
    width: 175,
    readOnly: true
  }

  const commonSortFilter = {
    sortable:true,
    filterable:true,
    getFilterOptions
  }

  const commonDate = {
    ...common,
    ...commonSortFilter,
    timezone: props.entity?.timezone,
    ...DateField,
    filterType: 'date-range' as DataTableFilterType
  }

  const commonTime = {
    ...common,
    ...TimeField,
    sortable:true,
    timezone: props.entity?.timezone
  }

  const cols:Array<DataTableColumn<TimeEntriesSelections>> = [{
      ...commonDate,
      name: 'checkedIn',
      reportId: 'formattedDate',
      label: 'Date',
      sort: DataTableColumnSort.ascending,
      filter: [moment().subtract(1, 'month'), moment()],
      readOnly: false
    }, {
      ...commonTime,
      name: 'checkedIn',
      reportId: 'formattedCheckedInTime',
      label: 'Checked in',
      readOnly: false
    }, {
      ...commonTime,
      name: 'checkedOut',
      reportId: 'formattedCheckedOutTime',
      label: 'Checked out',
      readOnly: false
    }, {
      ...common,
      name: 'hours',
      label: 'Hours',
    }, {
      ...common,
      ...commonSortFilter,
      name: 'user.name' as any,
      label: 'Name',
    }, {
      ...common,
      ...commonSortFilter,
      ...EmailField,
      name: 'user.email' as any,
      label: 'Email',
    }
  ]
  
  async function getFilterOptions(table:DataTable<TimeEntriesSelections>, col:DataTableColumn<TimeEntriesSelections>):Promise<Option[]> {
    const [success, result] = await timeEntriesFilterOptions({variables: {name: col.name, ...getOwner(props)}});
    
    return result.data?.timeEntriesQuery?.filterOptions as Option[] || [];
  }

  return cols;
}
