import * as React from 'react'

import { DataTable, MenuItem } from 'app2/components';
import { copyEmails } from 'app2/views/shared';

interface Props {
  table:Pick<DataTable<{parent?: { email: string }}>, 'allItems'>;
}

export function CopyParentEmailsAction(props:Props) {
  function render() {
    return <MenuItem label="Copy emails" onClick={handleCopyEmails} />;
  }

  function handleCopyEmails() {
    const enrollments = props.table.allItems;
    copyEmails(enrollments.map(r => r.parent.email));
  }

  return render();
}
