import * as React from 'react'

import { DiscountKind } from 'app2/api';
import { Collapsible, DropdownField, FormContent, InputField, Section, useFormInfo, FieldRendererProps, Tag, palette } from 'app2/components';
import { DiscountCombo } from 'app2/views';
import { CourseKindPicker, CoursePicker } from 'app2/views/shared';
import { purchaseableKinds } from 'app2/views/shared-public';

import { OrganizerDiscountsSelections } from './gql';

interface Props {
  value:OrganizerDiscountsSelections;
  ownerId: string;
  ownerType: 'season' | 'site';
}

export function DiscountForm(props:Props) {
  const {value:discount, ownerId, ownerType} = props;

  function render() {
    return <FormContent>
      <Section>
        <Section label="Code" name="code" required component={InputField} format={(val: string) => val.replace(/\s/g, '').toUpperCase()} />
        <Section label="Status" name="active" required none={false} edit={{ ...DropdownField, placeholder: 'Select a status', options: statusOptions }} display={{ render: renderStatus, stretch: false }} />
        <Section label="Type" name="kind" required none={false} edit={{ ...DropdownField, placeholder: 'Select a type', options: kindOptions }} display={{ render: renderKind, stretch: false }} />
      </Section>
      <Section>
        <Section label="Amount off" required component={DiscountCombo} />
        <Section name="usesCount" label="Used" width="40px" />
        <Section />
      </Section>
      {renderAdvanced()}
      </FormContent>
  }

  function renderAdvanced() {
    if (props.ownerType != 'site' && props.ownerType != 'season') {
      return;
    }

    const initialOpen = false;//discount.filterCourseKinds?.length || discount.filterCourses?.length;

    return <Collapsible label='Advanced' initialOpen={initialOpen}>
      <Section name='scope.courseKinds' label='Apply discount to specific activity types' component={<CourseKindPicker kinds={purchaseableKinds} flex='unset!important' />} multiple />
      {props.ownerType != 'site' && <Section name='scope.courses' label='Apply discount to specific activities' component={CoursePicker} multiple queryVars={{[`${ownerType}Id`]: ownerId, kinds: purchaseableKinds}} valueType='id' />}
    </Collapsible>
  }

  function renderStatus(props: FieldRendererProps<boolean>) {
    const option = statusOptions.find(o => o.value === props.info.value);
    return <Tag label={option.label} bg={option.bg} icon={null} />;
  }

  function renderKind(props: FieldRendererProps<DiscountKind>) {
    return kindLabels[props.info.value as DiscountKind];
  }

  const statusOptions = [
    { label: 'Active', value: true, bg: palette.secondary.lightGreen.hex },
    { label: 'Draft', value: false, bg: palette.secondary.lightYellow.hex }
  ];
  
  const kindLabels = {
    [DiscountKind.Activity]: 'Code',
    [DiscountKind.Sibling]: 'Sibling (automatic)'
  };
  
  const kindOptions = Object.values(DiscountKind).map(k => ({ label: kindLabels[k], value: k }));
  
 return render();
}